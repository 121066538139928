import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/api';
import { Dialog, DialogBackdrop, Transition } from '@headlessui/react';

const Role = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRole, setEditRole] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState({
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 1
  });
  const [permissions, setPermissions] = useState([]); // Fetch all available permissions

  // Fetch roles and permissions on mount
  useEffect(() => {
    fetchRoles();
    fetchPermissions();
  }, [currentPage]);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/roles`, {
        params: {
          search: searchTerm,
          page: currentPage,
        },
      });
      setRoles(response.data);
      setMeta(response.meta);
      setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch roles.');
      setLoading(false);
    }
  };

  const fetchPermissions = async () => {
    try {
      const response = await api.get('/permissions');
      setPermissions(response.data);
    } catch (error) {
      toast.error('Failed to fetch permissions.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this role?')) {
      try {
        await api.delete(`/roles/${id}`);
        toast.success('Role deleted successfully.');
        fetchRoles();
      } catch (error) {
        toast.error('Failed to delete role.');
      }
    }
  };

  const handleEdit = (role) => {
    setEditRole(role);
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    setEditRole(null);
    setIsModalOpen(true);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    fetchRoles();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleNextPage = () => {
    if (meta.current_page < meta.last_page) {
      setCurrentPage(meta.current_page + 1);
    }
  };

  const handlePrevPage = () => {
    if (meta.current_page > 1) {
      setCurrentPage(meta.current_page - 1);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= meta.last_page; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`px-3 py-1 rounded-lg border ${
            i === meta.current_page ? 'bg-primary text-white' : 'bg-white text-gray-700'
          } hover:bg-primary hover:text-white mx-1`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-auto px-4 py-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-primary text-3xl font-bold">Roles</h1>
        <button
          onClick={handleAdd}
          className="bg-primary text-white px-4 py-2 rounded-lg flex items-center hover:bg-accent"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add New Role
        </button>
      </div>

      <div className="flex space-x-4 mb-6">
        <div className="flex items-center border border-gray-300 rounded-lg p-2 shadow-md">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400 mr-2" />
          <input
            type="text"
            placeholder="Search Roles"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') fetchRoles();
            }}
            className="outline-none focus:ring-0 bg-transparent w-full text-gray-700"
          />
        </div>
      </div>

      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead className="bg-primary text-white">
          <tr>
            <th className="py-6 px-4 text-left">Role Name</th>
            <th className="py-6 px-4 text-center">Permissions</th>
            <th className="py-6 px-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {roles.length > 0 ? (
            roles.map((role) => (
              <tr key={role.id} className="hover:bg-bodyMain">
                <td className="py-2 px-4">{role.name}</td>
                <td className="py-2 px-4 text-center">
                  {role.permissions.map((perm) => perm.name).join(', ')}
                </td>
                <td className="py-2 px-4 text-center">
                  <div className="flex justify-center space-x-4">
                    <button onClick={() => handleEdit(role)} className="text-blue-500 hover:text-blue-700">
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button onClick={() => handleDelete(role.id)} className="text-red-500 hover:text-red-700">
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="py-4 text-center text-gray-500">No roles available.</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="mt-4 flex justify-between items-center">
        <span className="text-gray-500">
          Showing {meta.from} to {meta.to} of {meta.total} roles
        </span>

        <div className="flex items-center space-x-2">
          <button
            onClick={handlePrevPage}
            disabled={meta.current_page === 1}
            className={`bg-white px-4 py-2 rounded-lg border ${
              meta.current_page === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'
            }`}
          >
            Previous
          </button>
          {renderPageNumbers()}
          <button
            onClick={handleNextPage}
            disabled={meta.current_page === meta.last_page}
            className={`bg-white px-4 py-2 rounded-lg border ${
              meta.current_page === meta.last_page ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'
            }`}
          >
            Next
          </button>
        </div>
      </div>

      {isModalOpen && (
        <RoleModal
          role={editRole}
          permissions={permissions}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default Role;

export const RoleModal = ({ role, permissions, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    selectedPermissions: [],
  });

  useEffect(() => {
    if (role) {
      setFormData({
        name: role.name,
        selectedPermissions: role.permissions.map((perm) => perm.id),
      });
    }
  }, [role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePermissionChange = (permissionId) => {
    setFormData((prevData) => {
      const isSelected = prevData.selectedPermissions.includes(permissionId);
      const updatedPermissions = isSelected
        ? prevData.selectedPermissions.filter((id) => id !== permissionId)
        : [...prevData.selectedPermissions, permissionId];
      return { ...prevData, selectedPermissions: updatedPermissions };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { selectedPermissions, ...roleData } = formData;

      if (role) {
        await api.put(`/roles/${role.id}`, { ...roleData, permissions: selectedPermissions });
        toast.success('Role updated successfully');
      } else {
        await api.post('/roles', { ...roleData, permissions: selectedPermissions });
        toast.success('Role created successfully');
      }

      onSave();
    } catch (error) {
      toast.error('Error saving role.');
    }
  };

  return (
        <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
      <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {role ? 'Edit Role' : 'Add New Role'}
                </Dialog.Title>
                <div className="mt-4">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Role Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-lg font-medium text-gray-700 mb-1">Permissions</label>
                      <div className="grid grid-cols-1 gap-2">
                        {permissions.map((permission) => (
                          <div key={permission.id} className="flex items-center">
                            <input
                              type="checkbox"
                              id={`permission-${permission.id}`}
                              checked={formData.selectedPermissions.includes(permission.id)}
                              onChange={() => handlePermissionChange(permission.id)}
                              className="mr-2"
                            />
                            <label htmlFor={`permission-${permission.id}`} className="text-gray-700">
                              {permission.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="flex justify-end mt-6">
                      <button
                        type="button"
                        onClick={onCancel}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="bg-primary text-white px-4 py-2 rounded-lg">
                        {role ? 'Update' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
