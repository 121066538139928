import Staff from "../pages/Staff";
import { MapStyle } from "./googlemapstyle";


// export const API_BASE_URL = 'http://nhs.local/api/v1';
export const API_BASE_URL = 'https://nhsapi.mmindsdemo.com/api/v1';

// Token Storage
export const AUTH_TOKEN_KEY = 'authToken';
export const SECRET_ENCRYPTION_KEY = 'f8a1c5e94dbdb70a3b61a8d6fbd83f50d7bc1234e1ff7b5f36ad593fa345abc1'; 

// User roles
export const ROLE_KEY = 'role';

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
  STAFF: 'staff',
};

// Other constants
export const APP_NAME = 'NHS';
export const DEFAULT_THEME = 'light';
export const CURRENCY_CODE  = ' AED';

export const WALK_STATUS = {
  REQUESTED : 'requested',
  FINDING_WALKER : 'finding_walker',
  WALKER_ASSIGNED : 'walker_assigned',
  HEADING_TO_PICKUP : 'heading_to_pickup',
  PICKED_UP : 'picked_up',
  IN_PROGRESS : 'in_progress',
  COMPLETED : 'completed',
  CANCELLED : 'cancelled',
};

export const DELIVERY_STATUS = {
  REQUESTED: 'requested',
  FINDING: 'finding_driver',
  ASSIGNED: 'driver_assigned',
  HEADING_TO_PICKUP: 'heading_to_pickup',
  PICKUP_ARRIVED: 'arrived_at_pickup',
  EN_ROUTE_DROPPOFF: 'en_route_to_dropoff',
  DROPOFF_ARRIVED: 'arrived_at_dropoff',
  EN_ROUTE_RETURN: 'en_route_to_return',
  RETURN_ARRIVED: 'arrived_at_return',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};



export const GoogleMapStyle =  MapStyle