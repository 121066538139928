import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faTimes, faSyringe, faDog, faPaw, faGift, faUserShield, faWalking,faHandshake } from '@fortawesome/free-solid-svg-icons';
import { useLocation, NavLink } from 'react-router-dom';
import { routes } from '../../../config/utility';
import { motion } from 'framer-motion'; // Add framer-motion for animations


const Sidebar = () => {
  const location = useLocation(); // Get the current route location
  const [isOpen, setIsOpen] = useState(true); // Sidebar toggle state

  // Menu items
  const menuItems = [
    { icon: faSyringe, label: 'Pet Allergy', path: routes.pet_allergy }, 
    { icon: faDog, label: 'Pet Breed', path: routes.pet_breed },          
    { icon: faPaw, label: 'Pet Type', path: routes.pet_type },            
    { icon: faGift, label: 'Promo Code', path: routes.promo_code },      
    { icon: faUserShield, label: 'Membership', path: routes.membership }, 
    { icon: faWalking, label: 'Walk Tool', path: routes.walk_tool },      
    { icon: faHandshake, label: 'Partner', path: routes.partner },      
];

  // Function to toggle sidebar
  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <div className="sticky top-1">
      {/* Sidebar Toggle Button */}
      <motion.button
        onClick={toggleSidebar}
        className="absolute top-4 left-4 z-20 bg-gradient-to-br from-white to-transparent p-3 rounded-full shadow-lg text-accent hover:scale-110 hover:shadow-2xl transition-all duration-300 ease-in-out focus:outline-none"
        whileTap={{ scale: 0.9 }}
      >
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </motion.button>

      {/* Sidebar */}
      <motion.div
        initial={{ width: 0 }} // Initial sidebar width (hidden)
        animate={{ width: isOpen ? 260 : 70 }} // Toggle sidebar width
        transition={{ duration: 0.6, ease: "easeInOut" }}
        className={`bg-gradient-to-br from-primary to-gray-900 flex flex-col py-10 px-6 rounded-3xl overflow-hidden   `}
      >
        {/* Logo Section */}
        <motion.div
          className={`flex items-center justify-center mb-14 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: isOpen ? 1 : 0 }}
        >
          <img src="/assets/paw-logo.png" alt="NHS Logo" className="w-12 h-12 shadow-lg " />
        </motion.div>

        {/* Menu Items */}
        <ul className="space-y-8">
          {menuItems.map((item, index) => (
            <motion.li 
              key={index} 
              className="relative group"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: index * 0.1 }} // Staggered animation for items
            >
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  `flex items-center space-x-4 text-left text-sm tracking-wide font-medium relative group ${
                    isActive
                      ? 'text-white bg-gradient-to-r from-primary to-transparent rounded-lg'
                      : 'text-ash hover:text-white transition duration-300'
                  }`
                }
              >
                {/* Animated Icon */}
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  transition={{ type: "spring", stiffness: 300 }}
                  className={`flex items-center justify-center text-lg p-2 rounded-lg ${
                    location.pathname === item.path ? 'bg-transparent' : 'bg-transparent'
                  }`}
                >
                  <FontAwesomeIcon icon={item.icon} />
                </motion.div>

                {/* Only show text when sidebar is open */}
                {isOpen && <span className="group-hover:translate-x-1 transition-transform duration-300">{item.label}</span>}

                {/* Glowing border effect on hover */}
                <motion.div
                  className="absolute inset-0 border border-transparent rounded-lg transition-all duration-300"
                  layoutId="underline"
                />
              </NavLink>

              {/* Active Indicator */}
              {location.pathname === item.path && (
                <motion.div
                  layoutId="activeIndicator"
                  className="absolute -left-3 top-0 h-full w-1 bg-gradient-to-br from-white to-white rounded-r-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                />
              )}
            </motion.li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default Sidebar;
