// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7C3AED', // Tailwind 'primary'
    },
    secondary: {
      main: '#D8B4FE', // Tailwind 'secondary'
    },
    error: {
      main: '#EF4444', // Tailwind 'red' (optional)
    },
    warning: {
      main: '#F59E0B', // Tailwind 'amber'
    },
    info: {
      main: '#3B82F6', // Tailwind 'blue'
    },
    success: {
      main: '#10B981', // Tailwind 'green'
    },
    background: {
      default: '#F4F4FF', // Tailwind 'light'
      paper: '#FFFFFF',   // Tailwind 'white'
    },
    text: {
      primary: '#393B81',  // Tailwind 'dark'
      secondary: '#716E80', // Tailwind 'body'
    },
  },
  typography: {
    fontFamily: [
      'Poppins',   // Match Tailwind's font family
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(','),
  },
});

export default theme;