import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'; // Sad face icon for 404
import { routes } from '../../config/utility';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate(routes.dashboard); 
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-primary to-theme2  animate-fade-in">
      <div className="text-center p-8 bg-white shadow-2xl rounded-xl max-w-lg transform transition-transform hover:scale-105 duration-300">
        <SentimentDissatisfiedIcon className="text-primary mb-4 animate-bounce" style={{ fontSize: 100 }} />
        <h1 className="text-5xl font-extrabold text-tblue mb-4">404</h1>
        <p className="text-lg text-body mb-6">
          Oh no! The page you're looking for doesn't exist.
        </p>
        <p className="text-lg text-body mb-8">
          It seems you've wandered off somewhere... Let's get you back on track.
        </p>
        <Button
          variant="contained"
          onClick={handleGoHome}
          style={{
            backgroundColor: '#7C3AED', // primary color
            color: '#FFFFFF', // white
            padding: '12px 24px',
            borderRadius: '8px',
          }}
          className="hover:bg-accent transition duration-300 transform hover:scale-110"
        >
          Back To Dashboard
        </Button>
      </div>

      <div className="mt-10 text-body">
        <p className="text-lg text-white">
          Feeling lost? Head back to <span className="text-accent font-bold cursor-pointer" onClick={handleGoHome}>Dashboard</span> or contact support if the issue persists.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
