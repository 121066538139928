import React from "react";
import { Menu } from "@headlessui/react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UserCard = ({ user, handleEdit, handleDelete }) => {
    return (
        <div
            key={user.id}
            className="w-full flex items-center justify-between p-4 py-8 bg-white border border-gray-200 rounded-lg shadow-sm"
        >
            {/* Left side - Profile Image and Details */}
            <div className="flex items-center space-x-4">
                <img src={user.profile_photo ? user.profile_photo : 'https://via.placeholder.com/150'} alt="User" className="w-24 h-24 rounded" />
                <div>
                    <p className="text-sm text-gray-500">#{user.id}</p>
                    <p className="text-lg font-semibold">{user.name}</p>
                    <p className="text-sm text-gray-500">Join On {user.created_at}</p>
                </div>
            </div>

            {/* Middle section - Location and Contact */}
            <div className="flex flex-col space-y-1">
                <div>
                    <p className="text-sm text-gray-500">Username</p>
                    <p className="text-base font-semibold">{user.username}</p>
                </div>
            </div>
            <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                    <p className="text-sm text-gray-500">Phone Number</p>
                    {user.phone_verified_at && (
                        <span className="flex items-center text-green-600">
                            <FontAwesomeIcon icon={faCheckCircle} className="w-4 h-4" />
                            <span className="text-xs font-medium text-green-600 ml-1">Verified</span>
                        </span>
                    )}
                </div>
                <p className="text-base font-semibold">{user.phone}</p>
            </div>



            <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                    <p className="text-sm text-gray-500">Email Address</p>
                    {user.email_verified_at && (
                        <span className="flex items-center text-green-600">
                            <FontAwesomeIcon icon={faCheckCircle} className="w-4 h-4" />
                            <span className="text-xs font-medium text-green-600 ml-1">Verified</span>
                        </span>
                    )}
                </div>
                <p className="text-base font-semibold">{user.email}</p>
            </div>

            {/* Right side - Order History Button */}
            <div className="flex items-center space-x-4">
                <button className="px-4 py-2 text-white bg-primary rounded-lg hover:bg-accent focus:outline-none focus:ring-2 focus:ring-accent">
                    Booking History
                </button>

                {/* 3-dot menu icon */}
                <Menu as="div" className="relative inline-block text-left ">
                    {/* Three Dots Icon (Menu.Button) */}
                    <Menu.Button className="text-gray-500 hover:text-gray-700 cursor-pointer ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 18.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                            />
                        </svg>
                    </Menu.Button>

                    {/* Dropdown Items (Menu.Items) */}
                    <Menu.Items className="absolute right-0 mt-2 w-40 bg-bodyMain shadow-lg rounded-lg border border-accent z-10 focus:outline-none">
                        <div className="py-1">
                          

                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${active ? "bg-primary text-white" : "text-primary "
                                            } group flex rounded-md items-center justify-center w-full px-4 py-2 text-sm`}
                                        onClick={handleEdit}
                                    >
                                        Edit
                                    </button>
                                )}
                            </Menu.Item>

                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${active ? "bg-primary text-white" : "text-primary "
                                            } group flex rounded-md items-center justify-center w-full px-4 py-2 text-sm`}
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Menu>
            </div>
        </div>
    );
};
