import React from 'react'

export const StatsCard = ({ title,value}) => {
    return (
        <div className="bg-secondary/20 backdrop-blur-lg text-center p-6 rounded-xl shadow-lg border border-white/20">
            <h2 className="text-primary  font-bold text-2xl mb-4">{title}</h2>
            <p className="text-4xl font-bold text-accent">{value}</p>
        </div>
    )
}
