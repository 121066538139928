import { useState } from 'react';
import api from '../../api/api';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../config/utility';

const Register = () => {
    const [form, setForm] = useState({
        name: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await api.post('/signup', form);
            navigate(routes.verify, { state: { email: form.email } }); // Redirect to OTP verification
        } catch (error) {
            console.error('Registration error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-primary to-theme2 animate-fade-in px-4">
            <div className="bg-white p-10 rounded-3xl shadow-lg w-full max-w-lg transform transition duration-500 ease-in-out hover:shadow-2xl hover:scale-105 animate-slide-up">
            <img
            src="/assets/logo.png"
            alt="Logo"
            className="w-32 mx-auto"
          />
                <h2 className="text-3xl font-extrabold text-primary tracking-tight text-center">
                    Create an Account
                </h2>
                <form onSubmit={handleRegister} className="space-y-6 mt-6">
                    <div className="relative">
                        <label htmlFor="name" className="block text-gray-600 font-medium mb-1">
                            Full Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm"
                            placeholder="Enter your full name"
                            required
                            value={form.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="relative">
                        <label htmlFor="email" className="block text-gray-600 font-medium mb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm"
                            placeholder="Enter your email"
                            required
                            value={form.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="relative">
                        <label htmlFor="password" className="block text-gray-600 font-medium mb-1">
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm"
                            placeholder="Enter your password"
                            required
                            value={form.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="relative">
                        <label htmlFor="password_confirmation" className="block text-gray-600 font-medium mb-1">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            name="password_confirmation"
                            id="password_confirmation"
                            className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm"
                            placeholder="Confirm your password"
                            required
                            value={form.password_confirmation}
                            onChange={handleChange}
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full py-3 text-white bg-primary rounded-lg shadow-lg hover:bg-primary-dark transition-transform transform hover:scale-105 duration-300 ease-in-out active:scale-95"
                    >
                        {loading ? 'Registering...' : 'Register'}
                    </button>
                </form>
                <div className="mt-6 text-center">
                    <Link to={routes.login}>
                        <a className="text-primary text-sm hover:underline">
                            Already have an account? Sign in
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Register;
