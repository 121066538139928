import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import api from '../../../../api/api';
import { convertToAmPm } from '../../../../config/utility';

const SlotSelector = ({ formData, setFormData, step }) => {
    const [slots, setSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(formData.slot || null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchSlots = async () => {
            if (step !== 5 || !formData.day) return; // Only fetch when on step 5 and day is selected

            setLoading(true);
            setError(false);

            try {
                const response = await api.get("/bookings/get-availability", {
                    params: {
                        services: formData.services,
                        day: formData.day,
                        location: formData.location,
                    },
                });

                if (response.data.slots) {
                    setSlots(response.data.slots);
                    setFormData({ ...formData, slot_token:response.data.token});
                } else {
                    setSlots([]);
                    setError(true);
                }
            } catch (err) {
                console.error("Error fetching slots:", err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchSlots();
    }, [step, formData.day]);

    const handleSlotSelect = (slot) => {
        setSelectedSlot(slot);
        setFormData({ ...formData, slot });
    };

    return (
        <div className="p-6 space-y-8">
            {/* Header Section */}
            <h2 className="text-primary text-3xl font-bold text-center">
                When would you like us to pamper you? ✨
            </h2>
            <p className="text-gray-600 text-center text-lg">
                Select a time slot that works best for you. We’ll handle the rest!
            </p>

            {/* Home Service Notice */}
            {formData.location === 'home' && (
                <div className="mb-6 p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded-md shadow-md">
                    <h3 className="text-yellow-600 font-semibold text-lg">
                        🏠 Home Service Alert!
                    </h3>
                    <p className="text-yellow-700 mt-2">
                        Since you're booking a **home-based service**, we’ve added an additional 
                        <strong> 1-hour buffer </strong> to the time slot to ensure our staff can travel and prepare.
                        Thank you for your patience! ❤️
                    </p>
                </div>
            )}

            {/* Slot Selection */}
            {loading ? (
                <p className="text-center text-gray-500 animate-pulse">
                    🔄 Hold tight! We're fetching the available slots for you...
                </p>
            ) : error || slots.length === 0 ? (
                <p className="text-center text-red-500 font-semibold">
                    🚫 Oops! No slots available for this day. 
                    Try picking another day to see more availability.
                </p>
            ) : (
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                    {slots.map((slot, index) => (
                        <motion.div
                            key={index}
                            className={`p-4 rounded-lg cursor-pointer border-2 transition-all duration-300 shadow-md ${
                                selectedSlot === slot
                                    ? 'bg-primary text-white shadow-lg border-primary'
                                    : 'bg-bodyMain text-dark border-gray-200 hover:border-primary hover:shadow-lg'
                            }`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleSlotSelect(slot)}
                        >
                            <p className="text-center font-medium">
                                🕒 {convertToAmPm(slot)} {/* Display converted time */}
                            </p>
                        </motion.div>
                    ))}
                </div>
            )}

            {/* Selected Slot Display */}
            {selectedSlot && (
                <div className="mt-6 text-center">
                    <p className="text-secondary text-lg font-medium">
                        ✅ You’ve locked in your spot for: 
                        <span className="text-primary font-bold"> {convertToAmPm(selectedSlot)}</span>
                    </p>
                    <p className="text-sm text-gray-500">
                        Don’t worry, you can change it before confirming your booking.
                    </p>
                </div>
            )}
        </div>
    );
};

export default SlotSelector;
