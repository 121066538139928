import React, { useContext } from 'react';
import StatsCard from './../components/dashboard/StatsCard';
import CustomerMap from '../components/dashboard/CustomerMap';
import TotalRevenue from '../components/dashboard/TotalRevenue';
import { UserContext } from '../context/UserContext';
import UserDashboard from './User/Dashboard';
import UnpaidUserDashboard from './User/UnpaidDashboard';
import AdminDashboard from './Admin/Dashboard';
import { ROLES } from '../config/constants';
import StaffDashboard from './Staff/Dashboard';

const Dashboard = () => {
  const { user, roles } = useContext(UserContext); // Get the roles from context

  return (
    <div className="p-6 space-y-6">

      {roles.includes(ROLES.ADMIN) && (
        <AdminDashboard />
      )}

      {roles.includes(ROLES.STAFF) && (
        <StaffDashboard />
      )}

      {roles.includes(ROLES.USER) && (
        <>
          {/* {user.ispaid ? <UserDashboard /> : <UserDashboard />} */}
          {user.paid ? <UserDashboard /> : <UnpaidUserDashboard />}
        </>
      )}

    </div>
  );
};

export default Dashboard;
