import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api/api';
import { routes } from '../../config/utility';
import { UserContext } from '../../context/UserContext';
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const { contextLogin, updateContext } = useContext(UserContext);
    const { state } = useLocation(); // Get `email` from Forgot Password page
    const [form, setForm] = useState({
        otp: '',
        password: '',
        password_confirmation: '',
    });
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false); // For Resend OTP

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleResendOtp = async () => {
        setResendLoading(true);
        try {
            await api.post('/resend-otp', { email: state?.email });
            toast.success('OTP sent successfully!');
        } catch (error) {
            console.error('Error resending OTP:', error);
            toast.error('Failed to resend OTP. Please try again.');
        } finally {
            setResendLoading(false);
        }
    };


    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.post('/reset-password', {
                email: state?.email,
                otp: form.otp,
                password: form.password,
                password_confirmation: form.password_confirmation,
            });
            contextLogin(response.data);
            updateContext();
            navigate(routes.dashboard); // Redirect to dashboard
        } catch (error) {
            console.error('Error resetting password:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-primary to-theme2 animate-fade-in px-4">
            <div className="bg-white p-10 rounded-3xl shadow-lg w-full max-w-lg transform transition duration-500 ease-in-out hover:shadow-2xl hover:scale-105 animate-slide-up">
                <img
                    src="/assets/logo.png"
                    alt="Logo"
                    className="w-32 mx-auto"
                />
                <h2 className="text-3xl font-extrabold text-primary tracking-tight text-center">
                    Reset Password
                </h2>
                <p className="text-sm text-gray-600 mt-2 text-center">
                    Enter the OTP sent to your email and your new password.
                </p>
                <form onSubmit={handleResetPassword} className="space-y-6 mt-6">
                    <div className="relative">
                        <label htmlFor="otp" className="block text-gray-600 font-medium mb-1">
                            OTP Code
                        </label>
                        <input
                            type="text"
                            name="otp"
                            id="otp"
                            className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm"
                            placeholder="Enter OTP"
                            required
                            value={form.otp}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="relative">
                        <label htmlFor="password" className="block text-gray-600 font-medium mb-1">
                            New Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm"
                            placeholder="Enter new password"
                            required
                            value={form.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="relative">
                        <label htmlFor="password_confirmation" className="block text-gray-600 font-medium mb-1">
                            Confirm New Password
                        </label>
                        <input
                            type="password"
                            name="password_confirmation"
                            id="password_confirmation"
                            className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm"
                            placeholder="Confirm new password"
                            required
                            value={form.password_confirmation}
                            onChange={handleChange}
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full py-3 text-white bg-primary rounded-lg shadow-lg hover:bg-primary-dark transition-transform transform hover:scale-105 duration-300 ease-in-out active:scale-95"
                    >
                        {loading ? 'Resetting Password...' : 'Reset Password'}
                    </button>
                </form>
                <div className="mt-6 text-center">
                    <a href="/login" className="text-primary text-sm hover:underline">
                        Back to Login
                    </a>
                </div>

                <button
                    onClick={handleResendOtp}
                    type="button"
                    disabled={resendLoading}
                    className={`text-primary hover:underline text-sm flex items-center transition duration-300 ${resendLoading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                >
                    {resendLoading ? (
                        <>
                            <svg
                                className="animate-spin h-4 w-4 mr-2 text-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                                ></path>
                            </svg>
                            Sending...
                        </>
                    ) : (
                        'Resend OTP'
                    )}
                </button>

            </div>
        </div>
    );
};

export default ResetPassword;
