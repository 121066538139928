import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faPlus, faSyncAlt, faSearch, faHome, faStore, faEye, faMapMarkerAlt, faClock, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/api';
import { Dialog, DialogBackdrop, Transition } from '@headlessui/react';
import { convertToAmPm, formatPrice } from '../../config/utility';
import { StepWizard } from '../../components/StepWizard';
import { motion } from "framer-motion";
import AppointmentDaySelector from './partials/booking/AppointmentDaySelector';
import ServiceSelector from './partials/booking/ServiceSelector';
import ColorSelector from './partials/booking/ColorSelector';
import AdditionalDetails from './partials/booking/AdditionalDetails';
import SummaryPage from './partials/booking/SummaryPage';
import SlotSelector from './partials/booking/SlotSelector';

const UserBooking = ({ user }) => {
    const [data, setData] = useState([]);
    const [existingServices, setExistingServices] = useState([]);
    const [existingColors, setExistingColors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editData, setEditData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShowModalOpen, setIsShowModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [meta, setMeta] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 1,
    });

    useEffect(() => {
        fetchData();
    }, [filter, currentPage]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/bookings`, {
                params: {
                    search: searchTerm,
                    filter,
                    page: currentPage,
                },
            });
            setData(response.data);
            setMeta(response.meta);
            setExistingServices(response.services);
            setExistingColors(response.colors);
            setLoading(false);
        } catch (error) {
            toast.error('Failed to fetch bookings.');
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this booking?')) {
            try {
                await api.delete(`/bookings/${id}`);
                toast.success('Booking deleted successfully.');
                fetchData();
            } catch (error) {
                toast.error('Failed to delete booking.');
            }
        }
    };

    const handleEdit = (booking) => {
        setEditData(booking);
        setIsModalOpen(true);
    };
    const handleShow = (booking) => {
        setEditData(booking);
        setIsShowModalOpen(true);
    };

    const handleAdd = () => {
        setEditData(null);
        setIsModalOpen(true);
    };

    const handleSave = () => {
        setIsModalOpen(false);
        fetchData();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleShowCancel = () => {
        setIsShowModalOpen(false);
    };

    const handleNextPage = () => {
        if (meta.current_page < meta.last_page) {
            setCurrentPage(meta.current_page + 1);
        }
    };

    const handlePrevPage = () => {
        if (meta.current_page > 1) {
            setCurrentPage(meta.current_page - 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= meta.last_page; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    className={`px-3 py-1 rounded-lg border border-primary ${i === meta.current_page
                        ? "bg-primary text-white"
                        : "bg-white text-gray-700"
                        } hover:bg-light hover:text-primary mx-1`}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="mx-auto px-4 py-6">
            {/* Header and Actions */}
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-primary text-3xl font-bold">Bookings</h1>
                <button
                    onClick={handleAdd}
                    className="bg-primary text-white px-4 py-2 rounded-lg flex items-center hover:bg-accent"
                >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Add New Booking
                </button>
            </div>

            {/* Search and Filter */}
            <div className="flex space-x-4 mb-6">
                {/* Search Input */}
                <div className="flex items-center border border-gray-300 rounded-lg p-2 shadow-md transition-all duration-300 hover:shadow-lg focus-within:shadow-2xl focus-within:bg-bodyMain">
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="text-gray-400 mr-2 transition-transform duration-300 hover:scale-110"
                    />
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                fetchData();
                            }
                        }}
                        className="outline-none focus:ring-0 bg-transparent w-full text-gray-700 placeholder-gray-400 focus:placeholder-gray-500 focus:text-black"
                    />
                </div>

                {/* Filter Dropdown */}
                <select
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="border rounded-lg p-2 shadow-sm"
                >
                    <option value="all">All Bookings</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select>
            </div>

            {/* Bookings Table */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {data.length > 0 ? (
                    data.map((booking) => (
                        <div className="relative bg-white/80 backdrop-blur-lg rounded-xl shadow-xl hover:shadow-2xl transition-all duration-300 overflow-hidden">

                            {/* Gradient Header */}
                            <div className="bg-gradient-to-r from-primary to-gradientEnd p-6">
                                <h2 className="text-white text-xl font-extrabold">
                                    {new Date(booking.day).toLocaleDateString('en-US', {
                                        weekday: 'long',
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric',
                                    })}
                                </h2>
                                <p className="text-white text-sm mt-2">
                                    <span className="font-bold">Appointment Time:</span> {" "}
                                    {convertToAmPm(booking.start_time, true)} - {convertToAmPm(booking.end_time, true)}
                                </p>
                            </div>

                            {/* Home/Salon Service Tag */}
                            <div className="absolute top-4 right-4">
                                <div
                                    className={`flex items-center space-x-2 p-2 px-3 rounded-full text-primary  bg-bodyMain
                                 
                                text-xs font-bold shadow-md`}
                                >
                                    {booking.home_service ? (
                                        <>
                                            <FontAwesomeIcon icon={faHome} />
                                            <span>Home Service</span>
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faStore} />
                                            <span>Salon Visit</span>
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* Service List */}
                            <div className="p-6">
                                <h3 className="text-dark text-lg font-bold mb-3">Selected Services</h3>
                                <div className="flex flex-wrap gap-3">
                                    {booking.services.map((service) => (
                                        <span
                                            key={service.id}
                                            className="inline-block bg-light text-dark text-sm font-medium px-4 py-2 rounded-full shadow-md hover:shadow-lg hover:bg-primary hover:text-white transition-all"
                                        >
                                            {service.name}
                                        </span>
                                    ))}
                                </div>
                            </div>

                            {/* Call-to-Action (View Details) */}
                            <div className="p-6">
                                <button
                                    onClick={() => handleShow(booking)}
                                    className="w-full bg-gradient-to-r from-primary to-gradientEnd text-white px-6 py-3 rounded-full text-center text-sm font-bold shadow-md hover:shadow-xl hover:scale-105 transition-all"
                                >
                                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                                    View Booking Details
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center col-span-full text-gray-500">
                        No bookings available.
                    </div>
                )}
            </div>

            {/* Pagination */}
            <div className="mt-4 flex justify-between items-center">
                <span className="text-gray-500">
                    Showing {meta.from} to {meta.to} of {meta.total} bookings
                </span>
                <div className="flex items-center space-x-2">
                    <button
                        onClick={handlePrevPage}
                        disabled={meta.current_page === 1}
                        className={`bg-white px-4 py-2 rounded-lg border border-primary ${meta.current_page === 1
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:accent"
                            }`}
                    >
                        Previous
                    </button>
                    {renderPageNumbers()}
                    <button
                        onClick={handleNextPage}
                        disabled={meta.current_page === meta.last_page}
                        className={`bg-white px-4 py-2 rounded-lg border border-primary ${meta.current_page === meta.last_page
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:accent"
                            }`}
                    >
                        Next
                    </button>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <CRUDModal
                    item={editData}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    existingServices={existingServices}
                    existingColors={existingColors}
                    user={user}
                />
            )}

            {isShowModalOpen && (
                <ShowModal
                    item={editData}
                    onCancel={handleShowCancel}
                />
            )}
        </div>
    );
};

export default UserBooking;

const steps = [
    { number: 1, label: "Select Service" },
    { number: 2, label: "Select Color" },
    { number: 3, label: "Additional Details" },
    { number: 4, label: "Select Day" },
    { number: 5, label: "Select Slot" },
    { number: 6, label: "Summary & Submit" },
];

export const CRUDModal = ({ item, onSave, onCancel, existingServices, existingColors, user }) => {
    const [step, setStep] = useState(1);


    const [formData, setFormData] = useState({
        day: '',
        services: [],
        colors: '',
        location: 'salon',
        addiional_info: '',
    });
    console.log(formData)

    useEffect(() => {
        if (item) {
            setFormData(item);
        }
    }, [item]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (item) {
                await api.put(`/bookings/${item.id}`, formData);
                toast.success('Booking updated successfully');
            } else {
                await api.post('/bookings', formData);
                toast.success('Booking created successfully');
            }
            onSave();
        } catch (error) {
            toast.error('Error saving booking.');
        }
    };



    const validateStep = () => {
        switch (step) {
            case 1: // Step 1: Service selection
                if (formData.services.length === 0) {
                    toast.error("Please select at least one service to continue.");
                    return false;
                }
                break;

            case 2: // Step 2: Color selection (Optional)
                // No validation required, but you could add a soft warning if needed.
                break;

            case 3: // Step 3: Location selection
                if (!formData.location) {
                    toast.error("Please select a location (Home or Salon) to continue.");
                    return false;
                }
                break;

            case 4: // Step 4: Day selection
                if (!formData.day) {
                    toast.error("Please select a day for your appointment.");
                    return false;
                }
                break;

            case 5: // Step 5: Slot selection
                if (!formData.slot) {
                    toast.error("Please select a time slot for your appointment.");
                    return false;
                }
                break;

            case 6:
                if (!validateAllFields()) {
                    toast.error("Please fill out all required fields before submission.");
                    return false;
                }
                break;

            default:
                return true;
        }
        return true;
    };


    const validateAllFields = () => {
        let isValid = true; // Default assumption is that the form is valid

        if (!formData.services || formData.services.length === 0) {
            toast.error("Please select at least one service to continue.");
            isValid = false;
        }

        if (!formData.location) {
            toast.error("Please select a location (Home or Salon) to continue.");
            isValid = false;
        }

        if (!formData.day) {
            toast.error("Please select a day for your appointment.");
            isValid = false;
        }

        if (!formData.slot) {
            toast.error("Please select a time slot for your appointment.");
            isValid = false;
        }

        return isValid; // Returns true if all fields are valid
    };

    const handleNextStep = () => {
        if (validateStep()) {
            if (step < steps.length) {
                setStep(step + 1);

            }
        }
    };

    const handlePrevStep = () => setStep(step - 1);

    return (
        <Transition appear show as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onCancel}>
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                                <div className="flex items-center justify-between ">
                                    <StepWizard
                                        step={step}
                                        setStep={setStep}
                                        steps={steps}
                                    />
                                </div>
                                <form onSubmit={handleSubmit} className="mt-4">

                                    {
                                        step == 1 && (
                                            <>
                                                <ServiceSelector formData={formData} setFormData={setFormData} services={existingServices} />
                                            </>
                                        )

                                    }
                                    {
                                        step == 2 && (
                                            <>
                                                <ColorSelector formData={formData} setFormData={setFormData} colors={existingColors} />
                                            </>
                                        )

                                    }
                                    {
                                        step == 3 && (
                                            <>
                                                <AdditionalDetails formData={formData} setFormData={setFormData} />
                                            </>
                                        )

                                    }
                                    {
                                        step == 4 && (
                                            <>
                                                <AppointmentDaySelector formData={formData} setFormData={setFormData} />
                                            </>
                                        )

                                    }
                                    {
                                        step == 5 && (
                                            <>
                                                <SlotSelector formData={formData} setFormData={setFormData} step={step} />
                                            </>
                                        )

                                    }
                                    {
                                        step == 6 && (
                                            <>
                                                <SummaryPage formData={formData} services={existingServices} colors={existingColors} user={user} />
                                            </>
                                        )

                                    }



                                    {/* Navigation buttons */}
                                    <div className="my-8 flex justify-between">
                                        {step > 1 && (
                                            <>
                                                <button
                                                    type="button"
                                                    onClick={handlePrevStep}
                                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
                                                >
                                                    Back
                                                </button>

                                                {step === 6 && (

                                                    <motion.button
                                                        type="sumbit" // Change to "button" to prevent default form submission
                                                        whileHover={{ scale: 1.05 }}
                                                        whileTap={{ scale: 0.95 }}
                                                        className="bg-primary text-white font-semibold px-6 py-3 rounded-lg shadow-md transition-all duration-300 ease-in-out"
                                                    >
                                                        Confirm Appointment
                                                    </motion.button>
                                                )}
                                            </>
                                        )}
                                        {step < 6 && step > 0 && (
                                            <button
                                                type="button"
                                                onClick={handleNextStep}
                                                className="bg-primary text-white px-4 py-2 rounded-lg"
                                            >
                                                Next
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export const ShowModal = ({ item, onCancel }) => {
    if (!item) return null;


    return (
        <Transition appear show as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onCancel}>
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-0 m-0 text-center">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-0 text-left align-middle shadow-xl transition-all">

                                {/* Header */}
                                <div className="bg-gradient-to-r from-primary to-gradientEnd p-8 text-white rounded-t-2xl">
                                    <h2 className="text-3xl font-bold">Booking Details</h2>
                                    <p className="text-sm">Here's a detailed view of this booking</p>
                                </div>

                                {/* User Details */}
                                <div className="flex items-center gap-6 p-6">
                                    <img
                                        src={item.user.profile_photo}
                                        alt="User Profile"
                                        className="w-20 h-20 rounded-full border-4 border-primary object-cover"
                                    />
                                    <div>
                                        <h3 className="text-lg font-bold text-dark">{item.user.name}</h3>
                                        <p className="text-sm text-gray-500">{item.user.email}</p>
                                    </div>
                                </div>

                                {/* Booking Info */}
                                <div className="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                    <div className="bg-light p-4 rounded-xl shadow-sm flex items-center">
                                        <FontAwesomeIcon icon={faCalendarAlt} className="text-primary mr-3" />
                                        <p className="text-dark">
                                            <strong>Date:</strong> {new Date(item.day).toLocaleDateString('en-US')}
                                        </p>
                                    </div>

                                    <div className="bg-light p-4 rounded-xl shadow-sm flex items-center">
                                        <FontAwesomeIcon icon={faClock} className="text-primary mr-3" />
                                        <p className="text-dark">
                                            <strong>Time Slot:</strong> {convertToAmPm(item.start_time, true)} - {convertToAmPm(item.end_time, true)}
                                        </p>
                                    </div>

                                    <div className="bg-light p-4 rounded-xl shadow-sm flex items-center">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary mr-3" />
                                        <p className="text-dark">
                                            <strong>Service:</strong> {item.home_service ? 'Home Service' : 'Salon Visit'}
                                        </p>
                                    </div>
                                </div>

                                {/* Assigned Staff */}
                                <div className="p-6">
                                    <h3 className="text-md font-bold text-dark mb-4">Assigned Staff</h3>
                                    <div className="flex gap-4">
                                        {item.assigned_staffs.map((staff) => (
                                            <div key={staff.id} className="flex items-center bg-light p-4 rounded-xl shadow-sm">
                                                <img
                                                    src={staff.profile_photo}
                                                    alt={staff.name}
                                                    className="w-12 h-12 rounded-full border-2 border-primary object-cover"
                                                />
                                                <div className="ml-3">
                                                    <p className="font-bold">{staff.name}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Selected Services */}
                                <div className="p-6">
                                    <h3 className="text-md font-bold text-dark mb-4">Services</h3>
                                    {item.services.map((service) => (
                                        <div key={service.id} className="bg-light p-4 rounded-xl shadow-sm mb-4">
                                            <h4 className="font-bold text-dark">{service.name}</h4>
                                            <p className="text-sm text-gray-500">
                                                Duration: {service.duration_min} | Price: ${service.price}
                                            </p>
                                        </div>
                                    ))}
                                </div>

                                <div className='p-6'>
                                    <h3 className="text-md font-bold text-dark mb-4">Selected Nail Art</h3>
                                    {item.color ? (
                                        <div className="flex items-center bg-light p-4 rounded-xl shadow-sm">
                                            <img
                                                src={item.color.image}
                                                alt={item.color.name}
                                                className="w-16 h-16 rounded-full border-2 border-primary object-cover"
                                            />
                                            <div className="ml-4">
                                                <p className="text-lg font-bold text-primary">{item.color.name}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-sm text-gray-500">No nail art selected for this booking.</p>
                                    )}
                                </div>

                                {/* Footer */}
                                <div className="flex justify-end p-6 space-x-4">
                                    <button
                                        onClick={onCancel}
                                        className="bg-gray-500 text-white px-6 py-2 rounded-full hover:bg-gray-700 transition-all"
                                    >
                                        Close
                                    </button>
                                </div>
                                {/* Footer Buttons */}


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};



