import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faPlus,
  faSearch,
  faKey,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api/api";
import { Dialog, DialogBackdrop, Transition } from "@headlessui/react";
import { CURRENCY_CODE } from "../../config/constants";
import { PermissionsModal } from "../../components/MembershipPermissionModal";

const Membership = () => {
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editData, setEditData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBenefitModalOpen, setIsBenefitModalOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState({
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 1,
  });

  // Fetch memberships on mount and when pagination/search term changes
  useEffect(() => {
    fetchMemberships();
  }, [currentPage]);

  const fetchMemberships = async () => {
    setLoading(true);
    try {
      const response = await api.get("/memberships", {
        params: {
          search: searchTerm,
          page: currentPage,
        },
      });
      setMemberships(response.data);
      setMeta(response.meta);
      setPermissions(response.membership_permissions);
      setLoading(false);
    } catch (error) {
      toast.error("Failed to fetch memberships.");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this membership?")) {
      try {
        await api.delete(`/memberships/${id}`);
        toast.success("Membership deleted successfully.");
        fetchMemberships(); // Refresh the table after deletion
      } catch (error) {
        toast.error("Failed to delete membership.");
      }
    }
  };

  const handleEdit = (item) => {
    setEditData(item);
    setIsModalOpen(true); // Show modal for editing
  };

  const handleAdd = () => {
    setEditData(null); // Reset any edit data
    setIsModalOpen(true); // Show modal for adding
  };

  const handleAddBenefit = (membership) => {
    setSelectedMembership(membership);
    setIsBenefitModalOpen(true); // Open modal for benefits
  };

  const handleSave = () => {
    setIsModalOpen(false); // Hide modal after saving
    fetchMemberships(); // Refresh list
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Hide modal without saving
  };

  const handleCancelBenefit = () => {
    setIsBenefitModalOpen(false); // Hide benefit modal without saving
  };

  const handleBenefitSave = () => {
    setIsBenefitModalOpen(false); // Hide benefit modal after saving
    fetchMemberships(); // Refresh memberships to show updated benefits
  };

  const handleEditPermissions = (membership) => {
    console.log(permissions)
    setSelectedMembership(membership);
    setIsPermissionModalOpen(true);
  };

  const handlePermissionSave = () => {
    setIsPermissionModalOpen(false);
    fetchMemberships();
  };

  const handlePermissionCancel = () => {
    setIsPermissionModalOpen(false);
  };

  // Pagination handlers
  const handleNextPage = () => {
    if (meta.current_page < meta.last_page) {
      setCurrentPage(meta.current_page + 1);
    }
  };

  const handlePrevPage = () => {
    if (meta.current_page > 1) {
      setCurrentPage(meta.current_page - 1);
    }
  };

  // Render page numbers for pagination
  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= meta.last_page; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`px-3 py-1 rounded-lg border border-primary ${i === meta.current_page
            ? "bg-primary text-white"
            : "bg-white text-gray-700"
            } hover:bg-light hover:text-primary mx-1`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };



  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-auto px-4 py-6">
      {/* Header and Actions */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-primary text-3xl font-bold">Memberships</h1>
        <button
          onClick={handleAdd}
          className="bg-primary text-white px-4 py-2 rounded-lg flex items-center hover:bg-accent"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add New Membership
        </button>
      </div>

      {/* Search */}
      <div className="flex space-x-4 mb-6">
        <div className="flex items-center border border-gray-300 rounded-lg p-2 shadow-md transition-all duration-300 hover:shadow-lg focus-within:shadow-2xl focus-within:bg-bodyMain">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-400 mr-2 transition-transform duration-300 hover:scale-110"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Keep updating state as user types
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchMemberships(); // Only set searchTerm on Enter key press
              }
            }}
            className="outline-none focus:ring-0 bg-transparent  w-full text-gray-700 placeholder-gray-400  focus:placeholder-gray-500 focus:text-black"
          />
        </div>
      </div>

      {/* Memberships Table */}
      <table className="min-w-full bg-white rounded-lg shadow-md table-auto overflow-visible">
        <thead className="bg-primary text-white">
          <tr>
            <th className="py-6 px-4 text-left">Name</th>
            <th className="py-6 px-4 text-left">Price</th>
            <th className="py-6 px-4 text-left">Description</th>
            <th className="py-6 px-4 text-center relative group">
              Benefits
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ml-2 text-sm text-white cursor-pointer"
              />
              <div className="absolute hidden group-hover:block text-primary  bg-light border border-grey text-xs py-1 px-2 rounded-lg w-48 -top-10 left-1/2 transform -translate-x-1/2 shadow-lg">
                These are only for UI purposes and displayed visually.
              </div>
            </th>
            <th className="py-6 px-4 text-center relative group">
              Permissions
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ml-2 text-sm text-white cursor-pointer"
              />
              <div className="absolute hidden group-hover:block text-primary  bg-light border border-grey text-xs py-1 px-2 rounded-lg w-48 -top-10 left-1/2 transform -translate-x-1/2 shadow-lg">
                These define system-level logic with specific variables.
              </div>
            </th>

            <th className="py-6 px-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {memberships.length > 0 ? (
            memberships.map((membership) => (
              <tr
                key={membership.id}
                className={`hover:bg-bodyMain ${!membership.is_active && "bg-red-200"
                  }`}
              >
                <td className="py-2 px-4">{membership.name}</td>
                <td className="py-2 px-4">
                  {CURRENCY_CODE + " " + membership.price}
                </td>
                <td className="py-2 px-4">{membership.description || "N/A"}</td>
                <td className="py-2 px-4 text-center">
                  <button
                    onClick={() => handleAddBenefit(membership)}
                    className="text-green-500 hover:text-green-700"
                  >
                    <FontAwesomeIcon icon={faKey} /> Manage Benefits
                  </button>
                </td>

                <td className="py-2 px-4 text-center">
                  <button
                    onClick={() => handleEditPermissions(membership)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <FontAwesomeIcon icon={faKey} /> Manage Permissions
                  </button>
                </td>

                <td className="py-2 px-4 text-center">
                  <div className="flex justify-center space-x-4">
                    <button
                      onClick={() => handleEdit(membership)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      onClick={() => handleDelete(membership.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="py-4 text-center text-gray-500">
                No memberships available.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="mt-4 flex justify-between items-center">
        <span className="text-gray-500">
          Showing {meta.from} to {meta.to} of {meta.total} memberships
        </span>
        <div className="flex items-center space-x-2">
          <button
            onClick={handlePrevPage}
            disabled={meta.current_page === 1}
            className={`bg-white px-4 py-2 rounded-lg border border-primary ${meta.current_page === 1
              ? "opacity-50 cursor-not-allowed"
              : "hover:accent"
              }`}
          >
            Previous
          </button>
          {renderPageNumbers()}
          <button
            onClick={handleNextPage}
            disabled={meta.current_page === meta.last_page}
            className={`bg-white px-4 py-2 rounded-lg border border-primary ${meta.current_page === meta.last_page
              ? "opacity-50 cursor-not-allowed"
              : "hover:accent"
              }`}
          >
            Next
          </button>
        </div>
      </div>

      {/* CRUD Modal for Adding/Editing Memberships */}
      {isModalOpen && (
        <MembershipModal
          item={editData}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}

      {/* Modal for Managing Membership Benefits */}
      {isBenefitModalOpen && (
        <BenefitModal
          membership={selectedMembership}
          onSave={handleBenefitSave}
          onCancel={handleCancelBenefit}
        />
      )}

      {isPermissionModalOpen && (
        <PermissionsModal
          membership={selectedMembership}
          defaultPermissions={permissions}
          onSave={handlePermissionSave}
          onCancel={handlePermissionCancel}
        />
      )}
    </div>
  );
};

export default Membership;

export const MembershipModal = ({ item, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    is_active: true,
    description: "",
  });

  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name,
        price: item.price,
        is_active: item.is_active,
        description: item.description || "",
      });
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Handle checkbox input for is_active
    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (item) {
        // Update membership
        await api.put(`/memberships/${item.id}`, formData);
        toast.success("Membership updated successfully");
      } else {
        // Create membership
        await api.post("/memberships", formData);
        toast.success("Membership created successfully");
      }

      onSave(); // Refresh and close the modal
    } catch (error) {
      toast.error("Error saving membership. Please check the form.");
    }
  };

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {item ? "Edit Membership" : "Add New Membership"}
                </Dialog.Title>

                <div className="mt-4">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Price
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        required
                        className="block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Description
                      </label>
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="block w-full p-2 border rounded-lg"
                      />
                    </div>

                    {/* Checkbox for is_active */}
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Is Active
                      </label>
                      <input
                        type="checkbox"
                        name="is_active"
                        checked={formData.is_active}
                        onChange={handleChange}
                        className="h-4 w-4 text-primary border-gray-300 rounded"
                      />
                      <span className="ml-2 text-sm text-gray-700">Active</span>
                    </div>

                    <div className="flex justify-end mt-6">
                      <button
                        type="button"
                        onClick={onCancel}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary text-white px-4 py-2 rounded-lg"
                      >
                        {item ? "Update" : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

// Benefit Modal for Adding/Editing/Deleting Multiple Benefits
export const BenefitModal = ({ membership, onSave, onCancel }) => {
  const [benefits, setBenefits] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
  });
  const [previewImage, setPreviewImage] = useState(null); // For image preview
  const [editMode, setEditMode] = useState(false); // To check if we're editing

  useEffect(() => {
    fetchBenefits();
  }, [membership]);

  // Fetch all benefits of a membership
  const fetchBenefits = async () => {
    try {
      const response = await api.get(`/memberships/${membership.id}`);
      setBenefits(response.data.benefits);
    } catch (error) {
      toast.error("Failed to load benefits.");
    }
  };

  // Handle form input changes (text fields)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file input and show preview of the image
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
    setPreviewImage(URL.createObjectURL(file)); // Generate a preview URL
  };

  // Handle form submission (Create or Update benefit)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    data.append("name", formData.name);
    data.append("description", formData.description);

    // Only append the image if there is a new one being uploaded
    if (formData.image instanceof File) {
      data.append("image", formData.image);
    }

    data.append("membership_id", membership.id);

    try {
      if (editMode) {
        data.append("_method", "put");
        // Update existing benefit (make sure formData.id is set in edit mode)
        await api.post(`/membership-benefits/${formData.id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Benefit updated successfully!");
      } else {
        // Create new benefit
        await api.post("/membership-benefits", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Benefit added successfully!");
      }

      fetchBenefits(); // Refresh the benefits list
      resetForm(); // Reset form after saving
    } catch (error) {
      toast.error("Failed to save benefit.");
    }
  };

  // Reset form and exit edit mode
  const resetForm = () => {
    setFormData({ name: "", description: "", image: null });
    setPreviewImage(null);
    setEditMode(false);
  };

  // Handle benefit deletion
  const handleDeleteBenefit = async (benefitId) => {
    if (window.confirm("Are you sure you want to delete this benefit?")) {
      try {
        await api.delete(`/membership-benefits/${benefitId}`);
        toast.success("Benefit deleted successfully");
        fetchBenefits(); // Refresh the list
      } catch (error) {
        toast.error("Failed to delete benefit.");
      }
    }
  };

  // Handle editing a benefit
  const handleEditBenefit = (benefit) => {
    setFormData({
      id: benefit.id, // Track benefit ID for editing
      name: benefit.name,
      description: benefit.description,
      image: benefit.icon?.url, // Use URL for displaying the image in edit mode
    });

    setPreviewImage(benefit.icon?.url); // Show current image in preview
    setEditMode(true); // Set edit mode
  };

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {editMode
                    ? `Edit Benefit for ${membership.name}`
                    : `Add Benefit for ${membership.name}`}
                </Dialog.Title>

                <div className="mt-4">
                  {/* Form to add or edit a benefit */}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Benefit Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Description
                      </label>
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Image
                      </label>
                      <input
                        type="file"
                        name="image"
                        onChange={handleFileChange}
                        className="block w-full p-2 border rounded-lg"
                      />
                    </div>

                    {/* Preview uploaded image */}
                    {previewImage && (
                      <div className="mb-4">
                        <img
                          src={previewImage}
                          alt="Preview"
                          className="w-32 h-32 object-cover rounded-lg"
                        />
                      </div>
                    )}

                    <div className="flex justify-end mt-6">
                      <button
                        type="button"
                        onClick={() => {
                          resetForm();
                          onCancel();
                        }}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary text-white px-4 py-2 rounded-lg"
                      >
                        {editMode ? "Update Benefit" : "Save Benefit"}
                      </button>
                    </div>
                  </form>
                </div>

                {/* List of existing benefits */}
                <div className="mt-8">
                  <h4 className="text-lg font-bold">Existing Benefits</h4>
                  <ul className="space-y-4 mt-4">
                    {benefits.map((benefit) => (
                      <li
                        key={benefit.id}
                        className="flex justify-between items-center p-4 border rounded-lg shadow-sm bg-white"
                      >
                        <div className="flex items-start space-x-4">
                          <img
                            src={benefit.icon.url}
                            alt={benefit.name}
                            className="w-20 h-20 object-cover rounded-lg shadow-md"
                          />
                          <div>
                            <p className="font-semibold text-lg text-gray-800">
                              {benefit.name}
                            </p>
                            <p className="text-sm text-gray-500">
                              {benefit.description || "No description"}
                            </p>
                          </div>
                        </div>
                        <div className="flex space-x-4">
                          <button
                            onClick={() => handleEditBenefit(benefit)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="w-5 h-5"
                            />
                          </button>
                          <button
                            onClick={() => handleDeleteBenefit(benefit.id)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              className="w-5 h-5"
                            />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};


