import React from "react";

const SingleUser = () => {
  return (
    <div className="p-8">
      {/* Heading Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-[#3E358F] text-3xl font-bold">User Detail</h1>
      </div>

      <div className="mx-auto bg-white shadow-lg rounded-lg border border-tblue overflow-hidden">
        {/* Header */}
        <div className="bg-tblue p-6 py-12 rounded-t-lg text-white">
        </div>

        {/* Main Content */}
        <div className="p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Left Section - User Details and User Pets */}
          <div className="md:col-span-2 space-y-6">
            {/* User Details */}
            <div className="flex flex-col md:flex-row items-center md:items-start gap-4">
              {/* User Image */}
              <div className="relative">
                <img
                  src="https://via.placeholder.com/150"
                  alt="User"
                  className="w-32 h-32 rounded-full"
                />
                <button className="absolute bottom-0 right-0 bg-purple-500 p-2 rounded-full text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.232 9.232l-6 6m0 0l6 6m-6-6h18"
                    />
                  </svg>
                </button>
              </div>

              {/* User Info */}
              <div>
                <h2 className="text-xl font-bold">Huda Shahid</h2>
                <p className="text-body text-sm">
                  Midnight Corner St. Suite 600 San Francisco, CADGE 94107
                </p>
                <div className="mt-3 flex items-center gap-2">
                  <div className="bg-gray-100 p-2 rounded-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-5 h-5 text-primary"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8l7-5m0 0l7 5m-7-5v16"
                      />
                    </svg>
                  </div>
                  <span className="text-body text-sm">+96 1234 5678</span>
                </div>
              </div>
            </div>

            {/* User Pets Section */}
            <div className="border-t border-gray-200 pt-6">
              <h3 className="text-lg font-semibold text-tblue">User Pets</h3>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                {["dog", "cat", "dalmatian", "bulldog", "bulldog2", "chihuahua"].map(
                  (pet, idx) => (
                    <div
                      key={idx}
                      className="flex flex-col items-center justify-center"
                    >
                      <img
                        src={`https://via.placeholder.com/100?text=${pet}`}
                        alt={pet}
                        className="w-20 h-20 rounded-lg"
                      />
                      <span className="text-sm font-medium text-body mt-2">
                        Hobo Brown
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {/* Right Section - Other Details */}
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-tblue">Other Details</h3>
            <p className="text-body mt-2 text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleUser;
