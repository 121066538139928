import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { routes } from '../../config/utility';

const AccessDeniedPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(routes.dashboard); // Navigates back to the previous page
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-primary to-theme2 animate-fade-in text-white">
      <div className="text-center p-6 bg-white shadow-lg rounded-xl max-w-md">
        <ErrorOutlineIcon className="text-red-500 mb-4" style={{ fontSize: 80 }} />
        <h1 className="text-4xl font-bold text-tblue mb-2">Access Denied</h1>
        <p className="text-body text-lg mb-6">
          Oops! You don’t have permission to access this page.
        </p>
        <Button
          variant="contained"
          onClick={handleGoBack}
          style={{
            backgroundColor: '#7C3AED', // primary color
            color: '#FFFFFF', // white
            padding: '10px 20px',
            borderRadius: '8px',
          }}
          className="hover:bg-accent transition duration-300 transform hover:scale-105"
        >
          Back To Home
        </Button>
      </div>
    </div>
  );
};

export default AccessDeniedPage;
