import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpa,
  faCalendarCheck,
  faGift,
  faGem,
  faHeart,
  faCalendarAlt,
  faCalendarPlus,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { UserContext } from "../../context/UserContext";

const UserDashboard = () => {

  const {user} = useContext(UserContext)
  // Sample data for charts
  const bookingTrendsData = [
    { day: "Mon", Bookings: 2, Revenue: 50 },
    { day: "Tue", Bookings: 5, Revenue: 150 },
    { day: "Wed", Bookings: 3, Revenue: 100 },
    { day: "Thu", Bookings: 4, Revenue: 120 },
    { day: "Fri", Bookings: 6, Revenue: 200 },
    { day: "Sat", Bookings: 8, Revenue: 300 },
    { day: "Sun", Bookings: 7, Revenue: 250 },
  ];

  return (
    <div className=" px-6  space-y-12 ">
      {/* Welcome Banner */}
      <div className="  rounded-3xl hover:shadow-primary transition-transform transform">
        <h1 className="text-4xl font-bold text-primary text-center">
          Welcome Back 💅
        </h1>
        <p className="text-dark text-center mt-4 text-lg">
          Get ready for your next luxurious salon experience. Track your
          bookings, explore memberships, and make every visit unforgettable!
        </p>
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {[
          {
            label: "Upcoming Appointments",
            count: 2,
            icon: faCalendarPlus,
            color: "text-primary",
          },
          {
            label: "Membership Status",
            count: user?.membership?.name,
            icon: faGem,
            color: "text-accent",
          },
          {
            label: "Booking Limit",
            count: user?.permission?.booking_limit,
            icon: faCalendarCheck,
            color: "text-primary",
          },
        ].map((item, idx) => (
          <motion.div
            key={idx}
            className="bg-white p-8 rounded-3xl shadow-lg flex items-center space-x-6 hover:shadow-primary transition-transform transform"
          >
            <div className="flex items-center justify-center w-14 h-14 rounded-full bg-light">
              <FontAwesomeIcon
                icon={item.icon}
                className={`text-3xl ${item.color}`}
              />
            </div>
            <div>
              <h4 className="text-2xl font-bold text-primary">{item.count}</h4>
              <p className="text-body text-lg">{item.label}</p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Booking Trends Section */}
      {/* <div className="bg-white p-10 rounded-3xl shadow-lg hover:shadow-primary transition-transform transform">
        <h2 className="text-3xl font-bold text-primary mb-6">
          Weekly Booking Trends 📅
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={bookingTrendsData}>
            <CartesianGrid stroke="#f0f0f0" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <defs>
              <linearGradient id="colorBookings" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8C7A6B" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#D4B6A7" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="Bookings"
              stroke="#8C7A6B"
              fill="url(#colorBookings)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div> */}

      {/* Promotions and Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        {/* Promotions */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.3 }}
          className="md:col-span-4 lg:col-span-4 bg-gradient-to-r from-primary via-accent to-secondary p-8 rounded-3xl shadow-lg flex flex-col items-center text-white"
        >
          <FontAwesomeIcon icon={faGift} className="text-6xl mb-4" />
          <h3 className="text-2xl font-bold mb-2">Special Offer 🎉</h3>
          <p className="text-center text-sm mb-4">
            Enjoy <strong>20% off</strong> your next service. Use the code{" "}
            <span className="font-bold text-white">LUX20</span> at checkout.
          </p>
          <button className="bg-white text-primary px-6 py-3 rounded-lg hover:bg-light transition-transform transform">
            Redeem Now
          </button>
        </motion.div>

        {/* Quick Actions */}
        <div className="md:col-span-8 lg:col-span-8 bg-white p-10 rounded-3xl shadow-lg items-center justify-center text-center">
          <h3 className="text-3xl font-bold text-primary mb-6">
            Quick Access Tools 💅
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
            {[
              { label: "Book a Service", icon: faSpa, action: () => {} },
              { label: "View Membership", icon: faGem, action: () => {} },
              { label: "Schedule Appointment", icon: faCalendarAlt, action: () => {} },
              { label: "Invite Friends", icon: faGift, action: () => {} },
            ].map((item, idx) => (
              <button
                key={idx}
                onClick={item.action}
                className="flex items-center space-x-4 text-primary bg-light p-4 rounded-xl shadow-md hover:shadow-primary hover:bg-gradient-to-r hover:from-primary hover:to-accent hover:text-white transition-transform transform items-center justify-center text-center"
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  className="text-3xl "
                />
                <span className="text-lg font-semibold ">
                  {item.label}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Footer Call-to-Action */}
      {/* <div className="bg-gradient-to-br from-gradientStart to-gradientEnd text-white p-10 rounded-3xl shadow-lg text-center">
        <h3 className="text-2xl font-bold mb-4">Enhance Your Salon Experience 💎</h3>
        <p className="text-lg mb-6">
          Unlock exclusive benefits with our membership plans. Priority bookings, luxurious treatments, and more!
        </p>
        <button className="px-8 py-3 bg-white text-primary rounded-lg shadow-md hover:bg-light transition-transform transform hover:scale-110">
          Explore Memberships
        </button>
      </div> */}
    </div>
  );
};

export default UserDashboard;
