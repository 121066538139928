import React, { useState, useEffect } from 'react';
import { Dialog, DialogBackdrop, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { toast } from 'react-toastify';
import api from '../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const PromoCodeModal = ({ promoCode, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    code: '',
    description: '',
    discount_type: 'percentage',
    discount_value: 0,
    start_date: '',
    end_date: '',
    usage_limit: 0,
    min_order_value: 0,
    is_active: true,
  });

  useEffect(() => {
    if (promoCode) {
      setFormData(promoCode); // Populate form data if editing
    }
  }, [promoCode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Generate a random alphanumeric promo code
  const generatePromoCode = () => {
    const length = 8;
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';
    for (let i = 0; i < length; i++) {
      code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData({ ...formData, code });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (promoCode) {
        // Update Promo Code
        await api.put(`/promo-codes/${promoCode.id}`, formData);
        toast.success('Promo code updated successfully');
      } else {
        // Create Promo Code
        await api.post('/promo-codes', formData);
        toast.success('Promo code created successfully');
      }
      onSave(); // Callback to refresh the list and close the form
    } catch (error) {
      toast.error('Error saving promo code. Please check the form.');
    }
  };

  return (
        <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
      <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {promoCode ? 'Edit Promo Code' : 'Add New Promo Code'}
                </Dialog.Title>
                <div className="mt-4">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Promo Code</label>
                      <div className="flex">
                        <input
                          type="text"
                          name="code"
                          value={formData.code}
                          onChange={handleChange}
                          required
                          className="block w-full p-2 border rounded-lg"
                        />
                        
                        <button
                          type="button"
                          onClick={generatePromoCode}
                          className="ml-2 p-2 bg-primary text-white rounded-md hover:bg-secondary self-start"
                        >
                          <FontAwesomeIcon icon={faSyncAlt} />
                        </button>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Description</label>
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Discount Type</label>
                      <select
                        name="discount_type"
                        value={formData.discount_type}
                        onChange={handleChange}
                        className="mt-1 block w-full p-2 border rounded-lg"
                      >
                        <option value="percentage">Percentage</option>
                        <option value="fixed">Fixed</option>
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Discount Value</label>
                      <input
                        type="number"
                        name="discount_value"
                        value={formData.discount_value}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Start Date</label>
                      <input
                        type="datetime-local"
                        name="start_date"
                        value={formData.start_date}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">End Date</label>
                      <input
                        type="datetime-local"
                        name="end_date"
                        value={formData.end_date}
                        onChange={handleChange}
                        className="mt-1 block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Usage Limit</label>
                      <input
                        type="number"
                        name="usage_limit"
                        value={formData.usage_limit}
                        onChange={handleChange}
                        className="mt-1 block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Min Order Value</label>
                      <input
                        type="number"
                        name="min_order_value"
                        value={formData.min_order_value}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 border rounded-lg"
                      />
                    </div>

                    <div className="flex items-center">
                      <label className="block text-sm font-medium text-gray-700 mr-4">Active</label>
                      <input
                        type="checkbox"
                        name="is_active"
                        checked={formData.is_active}
                        onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                        className="h-4 w-4"
                      />
                    </div>

                    <div className="flex justify-end mt-6">
                      <button
                        type="button"
                        onClick={onCancel}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary text-white px-4 py-2 rounded-lg"
                      >
                        {promoCode ? 'Update' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PromoCodeModal;
