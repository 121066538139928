import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

// Data for the chart
const data = [

  { name: 'Jan', 2020: 30000, 2021: 40000 },
  { name: 'Feb', 2020: 20000, 2021: 35000 },
  { name: 'Mar', 2020: 25000, 2021: 45000 },
  { name: 'Apr', 2020: 38753, 2021: 12657 },
  { name: 'May', 2020: 38753, 2021: 12657 },
  { name: 'Jun', 2020: 50000, 2021: 53345 },
  { name: 'Jul', 2020: 50000, 2021: 24352 },
  { name: 'Aug', 2020: 50000, 2021: 12334 },
  { name: 'Sep', 2020: 50000, 2021: 34234 },
  { name: 'Oct', 2020: 50000, 2021: 12312 },
  { name: 'Nov', 2020: 50000, 2021: 12343 },
  { name: 'Dec', 2020: 50000, 2021: 60000 },
];

// Custom Tooltip Styling
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 shadow-lg rounded-md text-xs text-dark">
        <p className="font-semibold">{payload[0].payload.name}</p>
        <p className="text-primary">2020: ${payload[0].value.toLocaleString()}</p>
        <p className="text-accent">2021: ${payload[1].value.toLocaleString()}</p>
      </div>
    );
  }
  return null;
};

const TotalRevenue = () => {
  return (
    <div className="bg-white rounded-xl p-6 shadow-lg">
      <h2 className="text-dark text-xl font-bold mb-4">Total Revenue</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          {/* Grid */}
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E5E5" />
          {/* X and Y Axes */}
          <XAxis
            dataKey="name"
            tick={{ fill: '#8C7A6B' }}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
          />
          <YAxis
            tick={{ fill: '#8C7A6B' }}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            domain={[0, 60000]}
            tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`}
          />
          {/* Tooltip */}
          <Tooltip content={<CustomTooltip />} />
          {/* Legend */}
          <Legend
            verticalAlign="top"
            align="right"
            iconType="circle"
            iconSize={10}
            wrapperStyle={{ top: -20, right: 20 }}
          />
          {/* Lines */}
          <Line
            type="monotone"
            dataKey="2020"
            stroke="#8C7A6B"
            strokeWidth={3}
            dot={{ r: 5, stroke: '#8C7A6B', strokeWidth: 2, fill: '#fff' }}
            activeDot={{ r: 8, fill: '#8C7A6B', stroke: '#fff', strokeWidth: 2 }}
          />
          <Line
            type="monotone"
            dataKey="2021"
            stroke="#D4B6A7"
            strokeWidth={3}
            dot={{ r: 5, stroke: '#D4B6A7', strokeWidth: 2, fill: '#fff' }}
            activeDot={{ r: 8, fill: '#D4B6A7', stroke: '#fff', strokeWidth: 2 }}
          />

          
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TotalRevenue;
