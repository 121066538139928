import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faPlus, faSyncAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/api';
import { Dialog, DialogBackdrop, Transition } from '@headlessui/react';
import { formatPrice } from '../../config/utility';

const Service = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editData, setEditData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState({
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 1,
  });

  useEffect(() => {
    fetchData();
  }, [filter, currentPage]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/services`, {
        params: {
          search: searchTerm,
          filter,
          page: currentPage,
        },
      });
      setData(response.data); // Update services data
      setMeta(response.meta); // Update pagination metadata
      setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch services.');
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      try {
        await api.delete(`/services/${id}`);
        toast.success('Service deleted successfully.');
        fetchData();
      } catch (error) {
        toast.error('Failed to delete service.');
      }
    }
  };

  const handleEdit = (service) => {
    setEditData(service);
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    setEditData(null);
    setIsModalOpen(true);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    fetchData();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleNextPage = () => {
    if (meta.current_page < meta.last_page) {
      setCurrentPage(meta.current_page + 1);
    }
  };

  const handlePrevPage = () => {
    if (meta.current_page > 1) {
      setCurrentPage(meta.current_page - 1);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= meta.last_page; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`px-3 py-1 rounded-lg border border-primary ${i === meta.current_page
            ? "bg-primary text-white"
            : "bg-white text-gray-700"
            } hover:bg-light hover:text-primary mx-1`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-auto px-4 py-6">
      {/* Header and Actions */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-primary text-3xl font-bold">Services</h1>
        <button
          onClick={handleAdd}
          className="bg-primary text-white px-4 py-2 rounded-lg flex items-center hover:bg-accent"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add New Service
        </button>
      </div>

      {/* Search and Filter */}
      <div className="flex space-x-4 mb-6">
        {/* Search Input */}
        <div className="flex items-center border border-gray-300 rounded-lg p-2 shadow-md transition-all duration-300 hover:shadow-lg focus-within:shadow-2xl focus-within:bg-bodyMain">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-400 mr-2 transition-transform duration-300 hover:scale-110"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchData();
              }
            }}
            className="outline-none focus:ring-0 bg-transparent w-full text-gray-700 placeholder-gray-400 focus:placeholder-gray-500 focus:text-black"
          />
        </div>

        {/* Filter Dropdown */}
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="border rounded-lg p-2 shadow-sm"
        >
          <option value="all">All Services</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>

      {/* Services Table */}
      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead className="bg-primary text-white">
          <tr>
            <th className="py-6 px-4 text-left">Name</th>
            <th className="py-6 px-4 text-left">Price</th>
            <th className="py-6 px-4 text-left">Duration</th>
            <th className="py-6 px-4 text-left">Description</th>
            <th className="py-6 px-4 text-left">Is Extra</th>
            <th className="py-6 px-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.length > 0 ? (
            data.map((service) => (
              <tr key={service.id} className={`hover:bg-bodyMain ${!service.is_active && "bg-red-200"
                }`}>
                <td className="py-2 px-4">{service.formatted_name}</td>
                <td className="py-2 px-4">{formatPrice(service.price)}</td>
                <td className="py-2 px-4">{service.formatted_duration || 'N/A'}</td>
                <td className="py-2 px-4">{service.description || 'N/A'}</td>
                <td className="py-2 px-4">{service.is_extra ? 'Yes' : 'No'}</td>
                <td className="py-2 px-4 text-center">
                  <div className="flex justify-center space-x-4">
                    <button
                      onClick={() => handleEdit(service)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      onClick={() => handleDelete(service.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="py-4 text-center text-gray-500">
                No services available.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="mt-4 flex justify-between items-center">
        <span className="text-gray-500">
          Showing {meta.from} to {meta.to} of {meta.total} services
        </span>
        <div className="flex items-center space-x-2">
          <button
            onClick={handlePrevPage}
            disabled={meta.current_page === 1}
            className={`bg-white px-4 py-2 rounded-lg border border-primary ${meta.current_page === 1
              ? "opacity-50 cursor-not-allowed"
              : "hover:accent"
              }`}
          >
            Previous
          </button>
          {renderPageNumbers()}
          <button
            onClick={handleNextPage}
            disabled={meta.current_page === meta.last_page}
            className={`bg-white px-4 py-2 rounded-lg border border-primary ${meta.current_page === meta.last_page
              ? "opacity-50 cursor-not-allowed"
              : "hover:accent"
              }`}
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <CRUDModal
          item={editData}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default Service;

export const CRUDModal = ({ item, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    description: '',
    duration_min: '',
    is_active: true,
    is_extra: false,
  });

  useEffect(() => {
    if (item) {
      setFormData(item);
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (item) {
        await api.put(`/services/${item.id}`, formData);
        toast.success('Service updated successfully');
      } else {
        await api.post('/services', formData);
        toast.success('Service created successfully');
      }
      onSave();
    } catch (error) {
      toast.error('Error saving service.');
    }
  };

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {item ? 'Edit Service' : 'Add New Service'}
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="block w-full p-2 border rounded-lg"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Price</label>
                    <input
                      type="number"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                      required
                      className="block w-full p-2 border rounded-lg"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Duration (minutes)</label>
                    <input
                      type="number"
                      name="duration_min"
                      value={formData.duration_min}
                      onChange={handleChange}
                      required
                      className="block w-full p-2 border rounded-lg"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      className="block w-full p-2 border rounded-lg"
                    />
                  </div>
                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      name="is_active"
                      checked={formData.is_active}
                      onChange={handleChange}
                      className="h-4 w-4"
                    />
                    <label className="block text-sm font-medium text-gray-700 mr-4 ml-2">Active</label>


                    <input
                      type="checkbox"
                      name="is_extra"
                      checked={formData.is_extra}
                      onChange={handleChange}
                      className="h-4 w-4"
                    />
                    <label className="block text-sm font-medium text-gray-700 mr-4 ml-2">Extra</label>
                  </div>
                  <div className="flex justify-end mt-6">
                    <button
                      type="button"
                      onClick={onCancel}
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-primary text-white px-4 py-2 rounded-lg"
                    >
                      {item ? 'Update' : 'Save'}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
