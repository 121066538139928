import React, { useContext, useState } from "react";
import Sidebar from "./Sidebar";
import Headerbar from "./Headerbar";
import { UserContext } from "../../context/UserContext";
import LogoutConfirmation from "../LogoutModal";

const Layout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const { user, contextLogout, roles } = useContext(UserContext);
  const [showLogoutModal, setShowLogoutModal] = useState(false); // State for logout modal

  const handleLogout = () => {
    contextLogout();
    setShowLogoutModal(false);
  };


  return (
    <div className="flex h-screen bg-bodyMain">
      {/* Sidebar */}
      <div
        className={`${isSidebarOpen ? "w-64" : "w-16"
          } transition-all duration-300 bg-transparent hidden sm:block`}
      >
        <Sidebar
          isOpen={isSidebarOpen}
          toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
          user={user}
          roles={roles}
          handleLogout={handleLogout}
          setShowLogoutModal={setShowLogoutModal}
        />
      </div>

      {/* Main content area */}
      <div className="flex-grow flex flex-col">
        <Headerbar
          user={user}
          handleLogout={handleLogout}
          setShowLogoutModal={setShowLogoutModal}
        />
        <div className="flex-grow p-4 h-full overflow-y-auto">{children}</div>

        <footer className="text-center py-4">
          © {new Date().getFullYear()} NHS
        </footer>
      </div>

      <LogoutConfirmation
        isOpen={showLogoutModal}
        onConfirm={handleLogout}
        onClose={() => setShowLogoutModal(false)}
      />
    </div>
  );
};

export default Layout;

{
  /* <div
                        className={`transition-all duration-300 fixed inset-6 sm:mt-4 sm:inset-20 sm:left-44 `}
                        style={{
                            backgroundImage: `url(${bgImg})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            opacity: 0.1,
                            zIndex: 0, // Ensure watermark stays behind content
                            pointerEvents: 'none', // Optional: allows clicks to pass through
                        }}
                    >
                    </div>

                
                    <div className="relative z-10">
                        {children}
                    </div> */
}
