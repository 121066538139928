import React from 'react';

const AdditionalDetails = ({ formData, setFormData }) => {
    // Handle text input for additional details
    const handleInputChange = (e) => {
        setFormData({ ...formData, addiional_info: e.target.value });
    };

    // Handle location selection (home-based or in-salon)
    const handleLocationChange = (location) => {
        setFormData({ ...formData, location });
    };

    return (
        <div className="p-6">
            <h2 className="text-primary text-3xl font-bold mb-6 text-center">Let Us Know More</h2>
            <p className="text-gray-600 text-center mb-6">
                Your comfort and satisfaction are our priority! Share any additional details or preferences, and let us know your preferred service location.
            </p>

            {/* Additional Details Textarea */}
            <div className="mb-8">
                <label
                    className="block text-primary font-semibold mb-3 text-lg"
                    htmlFor="addiional_info"
                >
                    Any Special Instructions?
                </label>
                <textarea
                    id="addiional_info"
                    rows="4"
                    className="w-full p-4 border border-gray-300 rounded-lg bg-bodyMain text-dark focus:ring-2 focus:ring-primary focus:outline-none"
                    placeholder="e.g., Please bring extra nail polish remover."
                    value={formData.addiional_info || ''}
                    onChange={handleInputChange}
                ></textarea>
            </div>

            {/* Service Location Selection */}
            <div>
                <h3 className="text-primary font-semibold text-lg mb-4">Where Should We Serve You?</h3>
                <div className="flex flex-wrap gap-4">
                    <button
                        type="button"
                        className={`w-full sm:w-auto px-6 py-3 rounded-lg font-medium transition-all duration-300 ${
                            formData.location === 'home'
                                ? 'bg-primary text-white shadow-lg'
                                : 'bg-bodyMain text-dark border hover:border-primary hover:shadow-md'
                        }`}
                        onClick={() => handleLocationChange('home')}
                    >
                        Home Service
                    </button>
                    <button
                        type="button"
                        className={`w-full sm:w-auto px-6 py-3 rounded-lg font-medium transition-all duration-300 ${
                            formData.location === 'salon'
                                ? 'bg-primary text-white shadow-lg'
                                : 'bg-bodyMain text-dark border hover:border-primary hover:shadow-md'
                        }`}
                        onClick={() => handleLocationChange('salon')}
                    >
                        In-Salon
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdditionalDetails;
