import React from 'react';
import Sidebar from '../setting/Sidebar';
import { default as MainLayout } from '../Layout';

const Layout = ({ children }) => {
  return (
    <MainLayout>
      <div className="flex flex-col lg:flex-row ">
        <div>
          <Sidebar />
        </div>

        {/* Main Content Area */}
        <div className="flex-grow flex flex-col">
          <div className="flex-grow p-4 h-full overflow-y-auto">
            {children}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Layout;
