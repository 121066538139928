import { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api/api';
import { UserContext } from '../../context/UserContext';
import { routes } from '../../config/utility';
import { toast } from 'react-toastify';

const VerifyOtp = () => {
  const { contextLogin } = useContext(UserContext);
  const { state } = useLocation(); // Pass `email` from registration
  const [otp, setOtp] = useState(new Array(6).fill('')); // Array to hold OTP digits
  const [loading, setLoading] = useState(false); // For OTP verification
  const [resendLoading, setResendLoading] = useState(false); // For Resend OTP
  const navigate = useNavigate();
  const inputRefs = useRef([]); // Refs for each OTP input box

  // Redirect to registration if email is missing
  useEffect(() => {
    if (!state || !state.email) {
      navigate(routes.registration);
    }
  }, [state, navigate]);

  const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const otpCode = otp.join(''); // Combine OTP digits into a string
      const response = await api.post('/verify-otp', { email: state?.email, otp: otpCode });
      contextLogin(response.data);
      navigate(routes.dashboard); // Redirect to dashboard
    } catch (error) {
      console.error('OTP verification error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setResendLoading(true);
    try {
      await api.post('/resend-otp', { email: state?.email });
      toast.success('OTP sent successfully!');
    } catch (error) {
      console.error('Error resending OTP:', error);
      toast.error('Failed to resend OTP. Please try again.');
    } finally {
      setResendLoading(false);
    }
  };

  // Handle OTP input change
  const handleChange = (value, index) => {
    if (!/^\d*$/.test(value)) return; // Only allow numeric input

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to next field
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // Handle backspace
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-primary to-theme2 animate-fade-in px-4">
      <div className="bg-white p-10 rounded-3xl shadow-lg w-full max-w-lg transform transition duration-500 ease-in-out hover:shadow-2xl hover:scale-105 animate-slide-up">
        <h2 className="text-3xl font-extrabold text-primary tracking-tight text-center">
          Verify Your Email
        </h2>
        <p className="text-sm text-gray-600 mt-2 text-center">
          Enter the 6-digit code sent to your email: <strong>{state?.email}</strong>
        </p>
        <form onSubmit={handleVerify} className="space-y-6 mt-6">
          {/* OTP Input */}
          <div className="flex justify-center space-x-2">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)} // Store reference
                className="w-12 h-12 border border-gray-300 rounded-lg text-center text-lg focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105"
              />
            ))}
          </div>

          {/* Resend OTP Button */}
          <div className="flex justify-between items-center">
            <button
              onClick={handleResendOtp}
              type="button"
              disabled={resendLoading}
              className={`text-primary hover:underline text-sm flex items-center transition duration-300 ${
                resendLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {resendLoading ? (
                <>
                  <svg
                    className="animate-spin h-4 w-4 mr-2 text-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                    ></path>
                  </svg>
                  Sending...
                </>
              ) : (
                'Resend OTP'
              )}
            </button>
          </div>

          {/* Verify Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 text-white bg-primary rounded-lg shadow-lg hover:bg-primary-dark transition-transform transform hover:scale-105 duration-300 ease-in-out active:scale-95 ${
              loading ? 'opacity-75 cursor-not-allowed' : ''
            }`}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                  ></path>
                </svg>
                Verifying...
              </div>
            ) : (
              'Verify'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;
