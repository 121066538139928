import axios from 'axios';
import { API_BASE_URL, AUTH_TOKEN_KEY } from '../config/constants';
import { toast } from 'react-toastify';

// Create an Axios instance
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Configure Axios globally to use contextLogout

// Request Interceptor - Adding the token to each request
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(AUTH_TOKEN_KEY); // Retrieve decrypted token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);


// Response Interceptor - Global response and error handling
api.interceptors.response.use(
    (response) => {
        // If the response contains a specific structure, simplify it
        if (response.data && response.data.result) {
            return response.data.result;
        }
        return response; // Return full response if structure is different
    },
    (error) => {
        // Handle common error responses globally here
        if (error.response) {
            const status = error.response.status;

            // Handle 401 Unauthorized
            if (status === 401) {
               
                toast.error('Session expired. Please log in again.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: true,
                });
                localStorage.removeItem(AUTH_TOKEN_KEY);
                localStorage.removeItem("roles");
                localStorage.removeItem("permission");
                localStorage.removeItem("user");
            }

            // Handle 422 Unprocessable Entity (validation errors)
            if (status === 422) {
                // Retrieve the error structure
                const errors = error.response.data.result?.data?.errors || {};

                // Prepare an array of error messages
                let errorMessages = [];

                if (Array.isArray(errors)) {
                    // If `errors` is an array (e.g., a single error array)
                    errorMessages = errors;
                } else if (typeof errors === 'object' && errors !== null) {
                    // If `errors` is an object, collect all error messages (Laravel-style errors)
                    errorMessages = Object.values(errors).flat(); // Flatten in case any value is an array
                }

                // Show a separate toast for each error message
                errorMessages.forEach((errorMessage) => {
                    toast.error(
                        <div className="flex items-center space-x-2">
                            <span className="font-semibold">{errorMessage}</span>
                        </div>,
                        {
                            position: 'top-right',
                            autoClose: 5000,
                            closeButton: ({ closeToast }) => (
                                <button
                                    onClick={closeToast}
                                    className="text-grey-900 hover:text-primary-300 transition duration-200 ease-in-out mb-2"
                                >
                                    ✕
                                </button>
                            ),
                        }
                    );
                });
            }



            // Handle other error statuses globally (500, 404, etc.)
            if (status >= 500) {
                const errors = error.response.data.result?.data?.errors || {};
                if (errors.error == "Unauthenticated.") {
                    localStorage.removeItem(AUTH_TOKEN_KEY); // Remove token
                    localStorage.removeItem('roles'); // Remove roles
                    localStorage.removeItem('user'); // Remove user
                    localStorage.removeItem(AUTH_TOKEN_KEY);
                    localStorage.removeItem("roles");
                    localStorage.removeItem("permission");
                    localStorage.removeItem("user");
                }

                toast.error('Server error. Please try again later.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }

            if (status === 404) {
                toast.error('Resource not found. Please check the URL.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }

            // Optionally, log the error for debugging purposes
            console.error('API error:', error.response.data);
        } else if (error.request) {
            // Handle network error or no response from server
            toast.error('Network error. Please check your connection.', {
                position: 'top-right',
                autoClose: 5000,
            });
        } else {
            // Handle any other errors
            toast.error('An unexpected error occurred.', {
                position: 'top-right',
                autoClose: 5000,
            });
        }

        return Promise.reject(error); // Still reject the promise for additional handling
    }
);

export default api;
