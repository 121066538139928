import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { routes } from './../../config/utility';
import Layout from '../layouts/Layout';
import SettingLayout from '../layouts/setting/Layout';
import { ROLES } from '../../config/constants';
import VerifyOtp from '../../pages/Authentication/VerifyOtp';

const isAuthenticated = (user) => user?.isAuthenticated;
const isEmailVerified = (user) => user?.email_verified_at;
const hasValidRoles = (roles) => Array.isArray(roles) && roles.length > 0;

// Checks if the user’s roles match any of the allowed roles
const hasAllowedRole = (userRoles, allowedRoles) => {
  if (!hasValidRoles(userRoles) || !hasValidRoles(allowedRoles)) {
    return false; // Return false if either roles array is invalid
  }
  return userRoles.some((role) => allowedRoles.includes(role));
};

const PrivateRoute = ({ children, allowedRoles = [], isSetting = false , specialCheck = false}) => {
  const { user, roles } = useContext(UserContext);

  // If no user or unauthenticated, redirect to login
  if (specialCheck) {
    if (!user || (roles.includes(ROLES.USER) && !user?.paid)) {
      return <VerifyOtp />
    }
  }

  // If no user or unauthenticated, redirect to login
  if (!user || !isAuthenticated(user)) {
    return <Navigate to={routes.login} />;
  }

  if (!user || !isEmailVerified(user)) {
    return <Navigate to={routes.verify} state={{ email: user.email }} />;
  }

  // If roles are missing or no matching role is found, redirect to 403
  if (!hasValidRoles(roles) || !hasAllowedRole(roles, allowedRoles)) {
    return <Navigate to={routes['403']} />;
  }

  // Determine layout based on isSetting prop
  const SelectedLayout = isSetting ? SettingLayout : Layout;

  return <SelectedLayout>{children}</SelectedLayout>;
};

export default PrivateRoute;
