import React, { Fragment, useEffect, useState } from "react";
import { Dialog, DialogBackdrop, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import api from "../api/api";

// Utility to deep clone an object (ensuring we don’t reference the original)
const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));

export const PermissionsModal = ({
  membership,
  defaultPermissions,
  onSave,
  onCancel,
}) => {
  const [permissions, setPermissions] = useState({});
  const [hoveredKey, setHoveredKey] = useState(null);

  useEffect(() => {
    const initialPermissions = cloneDeep(defaultPermissions);

    // Set initial permissions, marking new permissions
    if (membership.permissions) {
      Object.keys(initialPermissions).forEach((key) => {
        if (membership.permissions[key] !== undefined) {
          initialPermissions[key].value = membership.permissions[key];
        } else {
          initialPermissions[key].new = true; // Mark as new if not in membership permissions
        }
      });
    } else {
      Object.keys(initialPermissions).forEach((key) => {
        initialPermissions[key].new = true; // Mark all as new if membership.permissions is null
      });
    }

    setPermissions(initialPermissions);
  }, []); // Run only once on modal open

  const handlePermissionChange = (e, key) => {
    const { value } = e.target;
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [key]: {
        ...prevPermissions[key],
        value,
        new: false, // Remove new flag once the user sets a value
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = Object.keys(permissions).reduce((acc, key) => {
      acc[key] = permissions[key].value;
      return acc;
    }, {});

    try {
      await api.post(`/memberships/${membership.id}`, {
        _method: "PUT",
        membership_id: membership.id,
        membership_permission: payload,
        membershipPermissions: true,
      });
      toast.success("Permissions updated successfully!");
      onSave();
    } catch (error) {
      toast.error("Failed to update permissions.");
    }
  };

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setPermissions({}); // Reset permissions on close
          onCancel(); // Call the onCancel function
        }}
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-xl bg-white p-8 text-left shadow-2xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-semibold leading-6 text-dark mb-6"
                >
                  Edit Permissions for {membership.name}
                </Dialog.Title>

                <form onSubmit={handleSubmit} className="space-y-6">
                  {Object.entries(permissions).map(
                    ([key, { value, description, example, new: isNew }]) => (
                      <div
                        key={key}
                        className={`p-6 border rounded-lg shadow-sm ${
                          isNew
                            ? "bg-yellow-50 "
                            : "bg-light"
                        } hover:shadow-lg transition-shadow duration-200 relative`}
                      >
                        <div className="flex justify-between items-center mb-4">
                          <label className="block text-lg font-medium text-dark">
                            {key
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (c) => c.toUpperCase())}
                          </label>
                          <div
                            className="relative"
                            onMouseEnter={() => setHoveredKey(key)}
                            onMouseLeave={() => setHoveredKey(null)}
                          >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="text-accent cursor-pointer"
                            />
                            {hoveredKey === key && (
                              <div className="absolute top-5 right-0 mt-2 w-80 p-4 bg-light border border-grey rounded-lg shadow-lg z-20">
                                <p className="text-sm text-body">
                                  <strong>Description:</strong> {description}
                                </p>
                                {example && (
                                  <p className="mt-1 text-sm text-body">
                                    <strong>Example:</strong> {example}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {isNew && (
                          <span className="absolute top-2 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs font-semibold bg-yellow-400 text-white rounded-md">
                            Not Set
                          </span>
                        )}

                        <input
                          type="number"
                          name={key}
                          value={value}
                          onChange={(e) => handlePermissionChange(e, key)}
                          className="block w-full p-3 border border-grey rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent text-dark"
                        />
                      </div>
                    )
                  )}

                  <div className="flex justify-end mt-10 space-x-4">
                    <button
                      type="button"
                      onClick={onCancel}
                      className="px-5 py-2 rounded-lg bg-ash text-dark hover:bg-grey transition duration-150"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-5 py-2 rounded-lg bg-primary text-white hover:bg-accent transition duration-150 shadow-md"
                    >
                      Save Permissions
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
