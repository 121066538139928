import React from 'react';
import { convertToAmPm } from '../../../../config/utility';


const calculateServiceStartTime = (slot) => {
    if (!slot) return null; // No slot selected

    const [startTime] = slot.split(' - '); // Extract 12:00 from "12:00 - 13:30"
    const [hours, minutes] = startTime.split(':').map(Number); // Split 12:00 to [12, 00]
    const updatedHour = (hours + 1) % 24; // Add 1 hour and handle overflow (e.g., 23 + 1 = 0)

    // Format to 2-digit hour (e.g., 9 -> 09) and keep minutes the same
    const formattedTime = `${updatedHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return formattedTime; // Return the calculated time (e.g., 13:00)
};


const SummaryPage = ({ user, formData, services, colors }) => {
    const selectedServices = formData.services.map((id) => services.find((service) => service.id === id));
    const selectedColor = colors.find((color) => color.id === formData.color);
    const calculatedTime = formData.slot ? calculateServiceStartTime(formData.slot) : null;

    return (
        <div className="p-6 space-y-8">
            <h2 className="text-primary text-3xl font-bold text-center">Your Appointment Summary</h2>
            <p className="text-gray-600 text-center mb-8">
                Here’s everything you’ve selected for your appointment. Make sure it’s all set before confirming!
            </p>

            {/* Selected Day */}
            <div className="space-y-4">
                <h3 className="text-secondary text-xl font-semibold">Selected Day</h3>
                <p className="text-dark bg-bodyMain p-4 rounded-lg shadow-sm">
                    {formData.day || 'No day selected'}
                </p>
            </div>

            {/* Selected Services */}
            <div className="space-y-4">
                <h3 className="text-secondary text-xl font-semibold">Selected Services</h3>
                {selectedServices.length > 0 ? (
                    <ul className="space-y-4">
                        {selectedServices.map((service) => (
                            <li
                                key={service.id}
                                className="p-4 bg-bodyMain rounded-lg shadow-sm text-dark"
                            >
                                <div className="flex justify-between items-center">
                                    <p className="font-semibold text-primary text-lg">{service.name}</p>
                                    <p className="text-primary font-bold">${service.price}</p>
                                </div>
                                <p className="text-sm text-gray-500">Duration: {service.duration_min}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-dark bg-bodyMain p-4 rounded-lg shadow-sm">No services selected</p>
                )}
            </div>






            <div className="space-y-4">
                <h3 className="text-secondary text-xl font-semibold">Selected Slot</h3>
                {selectedColor ? (
                    <div className="p-4 bg-bodyMain rounded-lg shadow-sm flex items-center space-x-4">
                        <img
                            src={selectedColor.image}
                            alt={selectedColor.name}
                            className="w-14 h-14 rounded-full border border-gray-300 object-cover"
                        />
                        <p className="font-medium text-dark">{selectedColor.name}</p>
                    </div>
                ) : (
                    <p className="text-dark bg-bodyMain p-4 rounded-lg shadow-sm">No color selected</p>
                )}
            </div>

            <div className="space-y-4">
                <h3 className="text-secondary text-xl font-semibold">Selected Slot</h3>

                {formData.slot ? (
                    <p className="text-dark bg-bodyMain p-4 rounded-lg shadow-sm">
                        {convertToAmPm(formData.slot)}
                    </p>
                ) : (
                    <p className="text-dark bg-bodyMain p-4 rounded-lg shadow-sm">
                        No slot selected
                    </p>
                )}

                {formData.location === 'home' && formData.slot && calculatedTime && (
                    <div className="p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded-md shadow-md">
                        <h3 className="text-yellow-600 font-semibold text-lg mb-2 flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 mr-2 text-yellow-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1 4h.01M12 8h.01M21 12.9A9 9 0 1111.1 3a9 9 0 0110 9.9z" />
                            </svg>
                            Important Notice for Home Service
                        </h3>
                        <p className="text-yellow-700 mt-2">
                            Due to the nature of home-based service, our staff will arrive at your location
                            at approximately <strong className='text-xl'>{convertToAmPm(calculatedTime, true)}</strong>. This includes an additional
                            <strong> 1-hour buffer</strong> to accommodate travel and setup.
                            Thank you for your patience and understanding.
                        </p>
                    </div>
                )}
            </div>

            {/* Additional Details */}
            <div className="space-y-4">
                <h3 className="text-secondary text-xl font-semibold">Additional Details</h3>
                <p className="text-dark bg-bodyMain p-4 rounded-lg shadow-sm">
                    {formData.additionalDetails || 'No additional details provided'}
                </p>
            </div>

            {/* Service Location */}
            <div className="space-y-4">
                <h3 className="text-secondary text-xl font-semibold">Service Location</h3>
                {formData.location === 'home' ? (
                    <>
                        <div className="bg-bodyMain p-4 rounded-lg shadow-sm">
                            <p className="text-dark font-semibold">Home-Based Service</p>

                        </div>
                        {user.permission.home_service_charges > 0 && (
                            <div className="mt-4 p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded-md shadow-md">
                                <h3 className="text-yellow-600 font-semibold text-lg mb-2 flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 mr-2 text-yellow-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1 4h.01M12 8h.01M21 12.9A9 9 0 1111.1 3a9 9 0 0110 9.9z" />
                                    </svg>
                                    Important Notice for Home Service
                                </h3>


                                <p className="text-yellow-700 mt-2 text-md mb-3">
                                    A home service charge of {" "}
                                    <strong className='text-xl'>{user?.permission?.home_service_charges} AED</strong>
                                    {" "}will be collected by our employee at your home.
                                </p>

                                <p className="text-sm text-yellow-700">
                                    Enjoy the convenience of our home service while our professional staff comes to you.
                                    We appreciate your understanding of this small additional fee.
                                </p>
                            </div>
                        )}
                    </>
                ) : formData.location === 'salon' ? (
                    <div className="bg-bodyMain p-4 rounded-lg shadow-sm">

                        <p className="text-dark font-semibold">In-Salon Service</p>
                    </div>

                ) : (
                    <div className="bg-bodyMain p-4 rounded-lg shadow-sm">

                        <p className="text-dark">No location selected</p>
                    </div>

                )}
            </div>

            {/* Final Confirmation */}
            <div className="text-center mt-8">
                <p className="text-primary text-lg font-semibold">
                    Ready to finalize your appointment? Click below to proceed!
                </p>

            </div>
        </div>
    );
};

export default SummaryPage;
