import { useState } from 'react';
import api from '../../api/api';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../config/utility';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await api.post('/resend-otp', { email });
            navigate(routes.reset_password, { state: { email } }); // Redirect to Reset Password page with email
        } catch (error) {
            console.error('Error sending OTP:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNavigateToForgotPassword = () => {

    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-primary to-theme2 animate-fade-in px-4">
            <div className="bg-white p-10 rounded-3xl shadow-lg w-full max-w-lg transform transition duration-500 ease-in-out hover:shadow-2xl hover:scale-105 animate-slide-up">
            <img
            src="/assets/logo.png"
            alt="Logo"
            className="w-32 mx-auto"
          />
                <h2 className="text-3xl font-extrabold text-primary tracking-tight text-center">
                    Forgot Password
                </h2>
                <p className="text-sm text-gray-600 mt-2 text-center">
                    Enter your email to receive a password reset OTP.
                </p>
                <form onSubmit={handleForgotPassword} className="space-y-6 mt-6">
                    <div className="relative">
                        <label htmlFor="email" className="block text-gray-600 font-medium mb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm"
                            placeholder="Enter your email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full py-3 text-white bg-primary rounded-lg shadow-lg hover:bg-primary-dark transition-transform transform hover:scale-105 duration-300 ease-in-out active:scale-95"
                    >
                        {loading ? 'Sending OTP...' : 'Send OTP'}
                    </button>
                </form>
                <div className="mt-6 text-center">
                    <Link to={routes.login}>
                        <a className="text-primary text-sm hover:underline">
                            Back to Login
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
