import CryptoJS from 'crypto-js';
import { CURRENCY_CODE } from './constants';


// Save encrypted value of a key
export const encryptKeyValue = (key, value) => {
    const stringValue = typeof value === 'string' ? value : JSON.stringify(value); // Convert array/object to string
    const encryptedValue = CryptoJS.AES.encrypt(stringValue, process.env.REACT_APP_SECRET_ENCRYPTION_KEY).toString();
    localStorage.setItem(key, encryptedValue);
};

export const convertToAmPm = (slot, onlyStartTime = false) => {
    const [start, end] = slot.split(" - ");
    const convert = (time) => {
        const [hour, minute] = time.split(":");
        const h = parseInt(hour, 10);
        const period = h >= 12 ? "PM" : "AM";
        const formattedHour = h % 12 || 12; // Convert 0 to 12 for 12-hour format
        return `${formattedHour}:${minute} ${period}`;
    };

    if (onlyStartTime) {
        return convert(start);
    }
    return `${convert(start)} - ${convert(end)}`;
};


// get decryptKeyValue value of a key
export const decryptKeyValue = (key) => {
    const encryptedKeyValue = localStorage.getItem(key);
    if (encryptedKeyValue) {
        const bytes = CryptoJS.AES.decrypt(encryptedKeyValue, process.env.REACT_APP_SECRET_ENCRYPTION_KEY);
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        try {
            // Try to parse the decrypted value back into an object/array
            return JSON.parse(decryptedValue);
        } catch (e) {
            // If parsing fails, it's likely a simple string, so return as-is
            return decryptedValue;
        }
    }
    return null;
};


export const normalizePermissions = (permissions) => {
    return Object.keys(permissions).reduce((acc, key) => {
        const normalizedKey = key.toUpperCase();
        acc[normalizedKey] = permissions[key];
        return acc;
    }, {});
};

export const formatPrice = (price) => {
    return price + ' ' + CURRENCY_CODE
};



export const routes = {
    home: '/',
    dashboard: '/dashboard',
    review: '/review',
    user: '/user',
    user_detail: '/user/:email?',
    profile: '/profile/:username?',
    booking: '/booking',

    // Auth
    login: '/auth/login',
    registration: '/auth/registration',
    verify: '/auth/verify-account',
    forgot_password: '/auth/forgot-password',
    reset_password: '/auth/reset-password',

    // admin
    membership: '/membership',
    role: '/role',
    service: '/service',
    staff: '/staff',
    promo_code: '/promo-code',
    permission: '/permission',
    color: '/color',

    // error
    403: '/access-denied',
};



