import React, { useContext, useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBriefcase, faChartBar, faCog, faList, faThLarge, faTimes, faSignOutAlt, faWalking, faPaw, faUsers, faClipboardList, faTaxi, faSpa, faCalendarCheck, faBrush, faUserTie, faGem, faGift, faUserClock } from '@fortawesome/free-solid-svg-icons';
import { useLocation, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import { routes } from '../../config/utility';
import { UserContext } from '../../context/UserContext';
import LogoutConfirmation from '../LogoutModal';

const Sidebar = ({ isOpen, toggleSidebar, setShowLogoutModal, roles }) => {
  const location = useLocation();


  const menuItems = [
    {
      icon: faSpa, // A spa icon for the dashboard
      label: 'Dashboard',
      path: routes.dashboard,
      allowedRoles: ['admin', 'user','staff'],
      sortKey: 1,
    },
    {
      icon: faCalendarCheck, // A calendar check for booking
      label: 'Booking',
      path: routes.booking,
      allowedRoles: ['admin', 'user','staff'],
      sortKey: 3,
    },
    {
      icon: faBrush, // A brush icon for salon services
      label: 'Service',
      path: routes.service,
      allowedRoles: ['admin'],
      sortKey: 2,
    },
    {
      icon: faUserTie, // A user tie icon for customers
      label: 'Customers',
      path: routes.user,
      allowedRoles: ['admin'],
      sortKey: 6,
    },
    {
      icon: faGem, // A gem icon for memberships
      label: 'Membership',
      path: routes.membership,
      allowedRoles: ['admin'],
      sortKey: 3,
    },
    {
      icon: faGift, // A gift icon for promo codes
      label: 'Promo Code',
      path: routes.promo_code,
      allowedRoles: ['admin'],
      sortKey: 3,
    },
    {
      icon: faUserClock, // A gift icon for promo codes
      label: 'Staff',
      path: routes.staff,
      allowedRoles: ['admin'],
      sortKey: 3,
    },
  ];



  const filteredMenuItems = menuItems
    .filter(item => item.allowedRoles.some(role => roles.includes(role)))
    .sort((a, b) => a.sortKey - b.sortKey);

  return (
    <>
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: isOpen ? 240 : 70 }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
        className={`bg-gradient-bg flex flex-col items-center py-6 space-y-8 shadow-lg rounded-[20px] mx-2 my-5 overflow-hidden h-[calc(100vh-40px)]`}
      >
        {/* Sidebar Toggle Button */}
        <motion.button
          onClick={toggleSidebar}
          className="absolute top-8 left-6 z-20 bg-gradient-to-br from-white to-transparent p-3 rounded-full shadow-lg text-accent hover:scale-110 hover:shadow-2xl transition-all duration-300 ease-in-out focus:outline-none"
          whileTap={{ scale: 0.9 }}
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </motion.button>

        {/* Logo Section */}
        <motion.div
          className={`flex items-center justify-center mb-14 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: isOpen ? 1 : 0 }}
        >
          <img src="/assets/logo-white.png" alt="NHS Logo" className="w-20 h-20 " />
        </motion.div>

        {/* Menu Items */}
        <ul className="space-y-6">
          {filteredMenuItems.map((item, index) => (
            <motion.li
              key={index}
              className="relative group "
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  `flex items-center space-x-8 text-left text-sm tracking-wide font-medium relative group ${isActive
                    ? 'text-white bg-gradient-to-r from-primary to-transparent rounded-lg'
                    : 'text-white hover:text-white transition duration-300'
                  }`
                }
              >
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  transition={{ type: "spring", stiffness: 300 }}
                  className={`flex items-center justify-center text-lg p-2 rounded-lg ${location.pathname === item.path ? 'bg-transparent' : 'bg-transparent'}`}
                >
                  <FontAwesomeIcon icon={item.icon} />
                </motion.div>

                {isOpen && <span className="group-hover:translate-x-1 transition-transform duration-300">{item.label}</span>}
              </NavLink>

              {location.pathname === item.path && (
                <motion.div
                  layoutId="activeIndicator"
                  className="absolute -left-3 top-0 h-full w-1 bg-gradient-to-br from-white to-white rounded-r-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                />
              )}
            </motion.li>
          ))}
        </ul>

        <div className="flex-grow"></div>

        {/* Sign Out Button */}
        <motion.div
          onClick={() => setShowLogoutModal(true)}
          whileHover={{ scale: 1.1 }}
          className="text-[20px] text-white hover:bg-white hover:text-primary p-4 rounded-lg transition duration-200 w-[30px] h-[30px] flex justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
        </motion.div>
      </motion.div>
    </>
  );
};

export default Sidebar;
