import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faSave,
  faCrown,
  faToggleOn,
  faToggleOff,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { UserContext } from "../../context/UserContext";
import api from "../../api/api";

const AdminProfile = () => {
  const { user, updateContext } = useContext(UserContext); // Access UserContext
  const [userData, setUserData] = useState({
    name: user?.name || "",
    phone: user?.phone || "",
    profilePhoto: user?.profile_photo || "https://via.placeholder.com/150", // Default placeholder
    walletBalance: user?.wallet_balance || 0,
    membership: user?.membership || null,
    membership: user?.membership || null,
    autoRenew: user?.auto_renew || false,
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [newPhoto, setNewPhoto] = useState(null); // Local state for photo upload
  const [loading, setLoading] = useState(false);

  // Handle Photo Change
  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile_photo", file);
      formData.append("_method", "PUT");

      try {
        setLoading(true);
        const response = await api.post(`/user/update`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Update UI optimistically
        setNewPhoto(URL.createObjectURL(file));
        setUserData((prevData) => ({
          ...prevData,
          profilePhoto: response.data.profile_photo,
        }));

        // Sync with UserContext
        updateContext();

        toast.success("Profile photo updated!");
      } catch (error) {
        console.error("Error updating photo:", error);
        toast.error("Failed to update profile photo.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle Name Change
  const handleNameChange = (e) => {
    setUserData((prevData) => ({
      ...prevData,
      name: e.target.value,
    }));
  };

  // Handle Phone Change
  const handlePhoneChange = (phone) => {
    setUserData((prevData) => ({
      ...prevData,
      phone,
    }));
  };

  // Handle Auto-Renew Toggle
  const toggleAutoRenew = async () => {
    try {
      setLoading(true);
      toast.info("Auto-renewal Comming Soon!");

      // const response = await api.post("/user/toggle-auto-renew", {
      //   auto_renew: !userData.autoRenew,
      // });

      // setUserData((prevData) => ({
      //   ...prevData,
      //   autoRenew: response.data.auto_renew,
      // }));

      // updateContext();
      // toast.success("Auto-renewal updated!");
    } catch (error) {
      console.error("Error updating auto-renew:", error);
      toast.error("Failed to update auto-renewal.");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePassword = async () => {
    const { currentPassword, newPassword, confirmPassword } = passwordData;

    if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirm Password do not match!");
      return;
    }

    try {
      // Call your API to update the password
      const response = await api.post("/user/update", {
        _method: "PUT",
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      });

      toast.success("Password changed successfully!");
      setPasswordData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      toast.error("Failed to change password. Please try again.");
    }
  };

  // Save Profile Changes
  const handleSave = async () => {
    try {
      setLoading(true);

      // Update name and phone on the server
      const response = await api.post("/user/update", {
        _method: "PUT",
        editProfile: true,
        name: userData.name,
        phone: userData.phone,
      });

      setUserData((prevData) => ({
        ...prevData,
        name: response.data.name,
        phone: response.data.phone,
      }));

      updateContext();
      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row gap-8 bg-bodyMain p-8">
      {/* Main Section: Profile Details */}
      <div className="flex-grow">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-primary">
            Welcome, <span className="text-accent">{userData.name}!</span>
          </h1>
          <p className="text-lg text-gray-600">
            Manage your profile, track membership, and enjoy personalized care!
          </p>
        </div>

        {/* Profile Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-8">
          {/* Profile Photo */}
          <div className="bg-white p-8 rounded-3xl shadow-lg flex flex-col items-center justify-center text-center">
            {/* Profile Picture */}
            <div className="relative w-36 h-36 mb-4">
              <img
                src={newPhoto || userData.profilePhoto}
                alt="Profile"
                className="w-36 h-36 object-cover rounded-full shadow-md border-4 border-accent"
              />
              <label
                htmlFor="photo-upload"
                className="absolute bottom-0 right-0 bg-gradient-to-r from-primary to-accent p-3 rounded-full text-white shadow-md cursor-pointer"
              >
                <FontAwesomeIcon icon={faCamera} />
                <input
                  id="photo-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handlePhotoChange}
                />
              </label>
            </div>

            {/* User Name */}
            <h3 className="text-2xl font-bold text-primary">{userData.name}</h3>
          </div>

         
        </div>

        {/* Edit Form */}
        <div className="bg-white p-8 rounded-3xl shadow-lg mt-8">
          <h2 className="text-3xl font-bold text-primary mb-6 text-center">
            Edit Profile
          </h2>

          <div className="grid grid-cols-1 gap-6">
            {/* Email (Read-Only) */}
            <div>
              <label className="block text-gray-600 font-semibold">Email</label>
              <input
                type="email"
                value={user.email}
                readOnly
                className="w-full px-4 py-2 rounded-lg border bg-gray-100 text-gray-500 cursor-not-allowed"
                placeholder="Your email address"
              />
            </div>

            {/* Username (Read-Only) */}
            <div>
              <label className="block text-gray-600 font-semibold">
                Username
              </label>
              <input
                type="text"
                value={user.username}
                readOnly
                className="w-full px-4 py-2 rounded-lg border bg-gray-100 text-gray-500 cursor-not-allowed"
                placeholder="Your username"
              />
            </div>

            {/* Full Name */}
            <div>
              <label className="block text-gray-600 font-semibold">
                Full Name
              </label>
              <input
                type="text"
                value={userData.name}
                onChange={handleNameChange}
                className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                placeholder="Enter your name"
              />
            </div>

            {/* Phone Number */}
            <div>
              <label className="block text-gray-600 font-semibold">Phone</label>
              <PhoneInput
                country={"ae"} // Default to UAE
                value={userData.phone}
                onChange={handlePhoneChange}
                inputStyle={{
                  width: "90%",
                  padding: "14px 20px",
                  border: "1px solid #d1d5db",
                  borderRadius: "8px",
                  color: "#4b5563",
                  marginLeft: "45px", // Create space between flag and number input
                  height: "40px", // Increase the overall height of the input for a spacious look
                }}
                buttonStyle={{
                  borderRadius: "8px 0 0 8px",
                  paddingRight: "10px", // Ensure the button has enough padding inside
                  height: "40px", // Match the height of the input
                }}
                inputClass="focus:ring-purple-500 focus:border-purple-500"
                dropdownClass="phone-dropdown"
                searchPlaceholder="Search for country" // Placeholder for search input
                enableSearch={true} // Enable search functionality
                searchStyle={{
                  padding: "10px 12px",
                  fontSize: "14px",
                  border: "1px solid #d1d5db",
                  borderRadius: "8px",
                }}
              />
            </div>
            {/* Change Password Section */}
            <div className="mt-4 bg-gray-50 p-6 rounded-lg shadow-inner">
              <h3 className="text-xl font-semibold text-primary mb-4">
                Change Password
              </h3>
              <div className="grid grid-cols-1 gap-4">
                {/* Current Password */}
                <div>
                  <label className="block text-gray-600 font-semibold">
                    Current Password
                  </label>
                  <input
                    type="password"
                    name="currentPassword"
                    value={passwordData.currentPassword}
                    onChange={handlePasswordChange}
                    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="Enter current password"
                  />
                </div>

                {/* New Password */}
                <div>
                  <label className="block text-gray-600 font-semibold">
                    New Password
                  </label>
                  <input
                    type="password"
                    name="newPassword"
                    value={passwordData.newPassword}
                    onChange={handlePasswordChange}
                    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="Enter new password"
                  />
                </div>

                {/* Confirm New Password */}
                <div>
                  <label className="block text-gray-600 font-semibold">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    onChange={handlePasswordChange}
                    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="Re-enter new password"
                  />
                </div>

                {/* Save Password Button */}
                <button
                  onClick={handleChangePassword}
                  className="w-full py-3 bg-gradient-to-r from-primary via-accent to-secondary text-white rounded-lg font-bold transition-transform transform hover:scale-105"
                >
                  <FontAwesomeIcon icon={faLock} /> Change Password
                </button>
              </div>
            </div>

            {/* Save Profile Changes */}
            <button
              onClick={handleSave}
              className="w-full py-3 bg-gradient-to-r from-accent via-primary to-secondary text-white rounded-lg font-bold transition-transform transform hover:scale-105"
            >
              <FontAwesomeIcon icon={faSave} /> Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
