import React, { useContext, useState } from 'react';
import { Menu, MenuItem, IconButton, Badge, Avatar } from '@mui/material';
import { Search as SearchIcon, Notifications as NotificationsIcon, AccountCircle as AccountIcon, Menu as MenuIcon } from '@mui/icons-material';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { routes } from '../../config/utility';

const Headerbar = ({ user,handleLogout,setShowLogoutModal }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const { roles } = useContext(UserContext); // Get the roles from context
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle profile menu
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle notifications menu
  const handleNotificationMenuOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null);
  };

  const logoutHandle = () => {
    setAnchorEl(null);
    setShowLogoutModal(true)
  };

  // Navigate to Profile Page
  const handleProfileClick = () => {
    handleProfileMenuClose();
    if (user && user.username) {
      const profileUrl = routes.profile.replace(':username?', user.username);
      navigate(profileUrl);
    } else {
      console.warn("User data is not available.");
    }
  };

  return (
    <div className="bg-transparent w-full h-20 flex items-center justify-between px-8 mb-1 mt-4">
      {/* Left Section: Logo */}
      <div className="flex items-center space-x-2 mt-5 mb-4">
        <img src="/assets/logo.png" alt="NHS Logo" className="h-16" />
      </div>

      {/* Search Bar */}
      {/* {roles.includes('admin') && (
        <div className="flex-grow mx-12 relative max-w-4xl hidden sm:block">
          <input
            type="text"
            placeholder="Search"
            className="w-full py-4 px-6 rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-400 shadow-md text-gray-600 placeholder-gray-400 transition"
          />
          <SearchIcon className="absolute top-4 right-6 text-gray-400" />
        </div>
      )} */}

      {/* Right Section: Notifications and Profile */}
      <div className="flex items-center space-x-6">
        {/* Notifications */}
        <div className="relative">
          <IconButton
            onClick={handleNotificationMenuOpen}
            className="relative p-3 bg-gradient-to-br from-accent to-primary text-white rounded-full shadow-lg hover:shadow-primary hover:scale-105 transition-transform"
          >
            <Badge
              badgeContent={3}
              color="error"
              overlap="circular"
              className="font-bold"
            >
              <NotificationsIcon className="text-2xl" />
            </Badge>
          </IconButton>

          {/* Notification Dropdown */}
          <Menu
            anchorEl={notificationAnchorEl}
            keepMounted
            open={Boolean(notificationAnchorEl)}
            onClose={handleNotificationMenuClose}
            PaperProps={{
              style: {
                background: 'linear-gradient(to bottom, #FAF5F1, #F2E8DC)',
                borderRadius: '15px',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <MenuItem
              onClick={handleNotificationMenuClose}
              className="hover:bg-gradient-to-r from-primary to-accent text-dark font-semibold px-6 py-3 rounded-lg"
            >
              💅 You have a new booking confirmation!
            </MenuItem>
            <MenuItem
              onClick={handleNotificationMenuClose}
              className="hover:bg-gradient-to-r from-primary to-accent text-dark font-semibold px-6 py-3 rounded-lg"
            >
              ✨ Reminder: Your membership is expiring soon.
            </MenuItem>
            <MenuItem
              onClick={handleNotificationMenuClose}
              className="hover:bg-gradient-to-r from-primary to-accent text-dark font-semibold px-6 py-3 rounded-lg"
            >
              💎 Your payment was successfully processed.
            </MenuItem>
          </Menu>
        </div>

        {/* Profile Section */}
        <div className="relative">
          <IconButton
            onClick={handleProfileMenuOpen}
            className="p-3 bg-gradient-to-br from-accent to-primary text-white rounded-full shadow-lg hover:shadow-primary hover:scale-105 transition-transform"
          >
            <Avatar
              alt={user.name}
              src={user?.profile_photo ? user?.profile_photo : null}
              className="w-10 h-10 border-2 border-light shadow-inner"
            />
          </IconButton>

          {/* Profile Dropdown */}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
            PaperProps={{
              style: {
                background: 'linear-gradient(to bottom, #FAF5F1, #F2E8DC)',
                borderRadius: '15px',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <MenuItem
              onClick={handleProfileClick}
              className="hover:bg-gradient-to-r from-primary to-accent text-dark font-semibold px-6 py-3 rounded-lg"
            >
              👤 Profile
            </MenuItem>
            <MenuItem
              onClick={handleProfileMenuClose}
              className="hover:bg-gradient-to-r from-primary to-accent text-dark font-semibold px-6 py-3 rounded-lg"
            >
              ⚙️ Settings
            </MenuItem>
            <MenuItem
              onClick={logoutHandle}
              className="hover:bg-gradient-to-r from-primary to-accent text-dark font-semibold px-6 py-3 rounded-lg"
            >
              🚪 Logout
            </MenuItem>
          </Menu>
        </div>

        {/* Language Selector */}
        <div className="relative">
          <select
            className="py-2 px-4 bg-gradient-to-br from-light to-ash text-dark font-semibold rounded-full shadow-md hover:shadow-primary focus:outline-none transition-transform transform hover:scale-105"
            defaultValue="English"
          >
            <option value="English">🇬🇧 English</option>
            <option value="Arabic">🇦🇪 Arabic</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Headerbar;
