import React, { useState } from 'react';

const ColorSelector = ({ formData, setFormData, colors }) => {
    const [selectedColor, setSelectedColor] = useState(formData.color || null);

    // Handle selection
    const handleColorSelect = (colorId) => {
        const updatedColor = selectedColor === colorId ? null : colorId; // Deselect if already selected
        setSelectedColor(updatedColor);
        setFormData({ ...formData, color: updatedColor });
    };

    return (
        <div className="p-5">
            <h2 className="text-primary text-3xl font-bold mb-6 text-center">Choose Your Nail Color</h2>
            <p className="text-gray-600 text-center mb-6">
                Pick the perfect shade to express your style and personality. Remember, only one color can be chosen for this session!
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
                {colors.map((color) => {
                    const isSelected = selectedColor === color.id;

                    return (
                        <div
                            key={color.id}
                            className={`p-4 rounded-lg cursor-pointer border-2 transition-all duration-300 shadow-md ${
                                isSelected
                                    ? 'border-primary bg-primary text-bodyMain shadow-lg'
                                    : 'bg-bodyMain border-gray-200 hover:border-primary hover:shadow-lg'
                            }`}
                            onClick={() => handleColorSelect(color.id)}
                        >
                            <div className="flex flex-col items-center">
                                <img
                                    src={color.image}
                                    alt={color.name}
                                    className={`w-16 h-16 rounded-full mb-3 object-cover border-2 ${
                                        isSelected ? 'border-bodyMain' : 'border-gray-300'
                                    }`}
                                />
                                <p
                                    className={`text-center font-medium ${
                                        isSelected ? 'text-bodyMain' : 'text-dark'
                                    }`}
                                >
                                    {color.name}
                                </p>
                                
                            </div>
                        </div>
                    );
                })}
            </div>
            {selectedColor && (
                <div className="mt-6 text-center">
                    <p className="text-secondary text-lg font-medium">
                        You’ve chosen: <span className="text-primary font-bold">{colors.find((c) => c.id === selectedColor)?.name}</span>
                    </p>
                </div>
            )}
        </div>
    );
};

export default ColorSelector;
