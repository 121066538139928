import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faPlus, faSyncAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/api';
import { Dialog, DialogBackdrop, Transition } from '@headlessui/react';
import PromoCodeModal from '../../components/PromoCodeModal';



const PromoCode = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editData, setEditPromoCode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState({
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 1
  });


  // Fetch promo codes on mount and when filters change
  useEffect(() => {
    fetchData();
  }, [filter, currentPage]);

  // Fetch promo codes from the backend with search, filter, and pagination
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/promo-codes`, {
        params: {
          search: searchTerm,
          filter,
          page: currentPage,
        },
      });
      setData(response.data); // Set the promo codes
      setMeta(response.meta); // Update pagination metadata
      setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch promo codes.');
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this promo code?')) {
      try {
        await api.delete(`/promo-codes/${id}`);
        toast.success('Promo code deleted successfully.');
        fetchData(); // Refresh the table after deletion
      } catch (error) {
        toast.error('Failed to delete promo code.');
      }
    }
  };

  const handleEdit = (promoCode) => {
    setEditPromoCode(promoCode);
    setIsModalOpen(true); // Show modal for editing
  };

  const handleAdd = () => {
    setEditPromoCode(null); // Reset any edit data
    setIsModalOpen(true); // Show modal for adding
  };

  const handleSave = () => {
    setIsModalOpen(false); // Hide modal after saving
    fetchData(); // Refresh list
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Hide modal without saving
  };

  // Pagination handlers
  const handleNextPage = () => {
    if (meta.current_page < meta.last_page) {
      setCurrentPage(meta.current_page + 1);
    }
  };

  const handlePrevPage = () => {
    if (meta.current_page > 1) {
      setCurrentPage(meta.current_page - 1);
    }
  };

  // Render individual page buttons
  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= meta.last_page; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`px-3 py-1 rounded-lg border border-primary ${i === meta.current_page
            ? "bg-primary text-white"
            : "bg-white text-gray-700"
            } hover:bg-light hover:text-primary mx-1`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-auto px-4 py-6">
      {/* Header and Actions */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-primary text-3xl font-bold">Promo Codes</h1>
        <button
          onClick={handleAdd}
          className="bg-primary text-white px-4 py-2 rounded-lg flex items-center hover:bg-accent"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add New Promo Code
        </button>
      </div>

      {/* Search and Filter */}
      <div className="flex space-x-4 mb-6">
        {/* Search Input */}
        <div className="flex items-center border border-gray-300 rounded-lg p-2 shadow-md transition-all duration-300 hover:shadow-lg focus-within:shadow-2xl focus-within:bg-bodyMain">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-400 mr-2 transition-transform duration-300 hover:scale-110"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Keep updating state as user types
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchData(); // Only set searchTerm on Enter key press
              }
            }}
            className="outline-none focus:ring-0 bg-transparent  w-full text-gray-700 placeholder-gray-400  focus:placeholder-gray-500 focus:text-black"
          />
        </div>

        {/* Filter Dropdown */}
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="border rounded-lg p-2 shadow-sm"
        >
          <option value="all">All Promo Codes</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>


      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead className="bg-primary text-white">
          <tr>
            <th className="py-6 px-4 text-left">Code</th>
            <th className="py-6 px-4 text-left">Description</th>
            <th className="py-6 px-4 text-left">Discount Type</th>
            <th className="py-6 px-4 text-left">Discount Value</th>
            <th className="py-6 px-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.length > 0 ? (
            data.map((promo) => (
              <tr key={promo.id} className="hover:bg-light">
                <td className="py-2 px-4">{promo.code}</td>
                <td className="py-2 px-4">{promo.description}</td>
                <td className="py-2 px-4 capitalize">{promo.discount_type}</td>
                <td className="py-2 px-4">{promo.discount_value}</td>
                <td className="py-2 px-4 text-center">
                  <div className="flex justify-center space-x-4">
                    <button
                      onClick={() => handleEdit(promo)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      onClick={() => handleDelete(promo.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="py-4 text-center text-gray-500">
                No promo codes available.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="mt-4 flex justify-between items-center">
        {/* Total Count and Pagination Info */}
        <span className="text-gray-500">
          Showing {meta.from} to {meta.to} of {meta.total} promo codes
        </span>

        {/* Page Number Buttons */}
        <div className="flex items-center space-x-2">
          <button
            onClick={handlePrevPage}
            disabled={meta.current_page === 1}
            className={`bg-white px-4 py-2 rounded-lg border border-primary ${meta.current_page === 1
              ? "opacity-50 cursor-not-allowed"
              : "hover:accent"
              }`}
          >
            Previous
          </button>
          {renderPageNumbers()}
          <button
            onClick={handleNextPage}
            disabled={meta.current_page === meta.last_page}
            className={`bg-white px-4 py-2 rounded-lg border border-primary ${meta.current_page === meta.last_page
              ? "opacity-50 cursor-not-allowed"
              : "hover:accent"
              }`}
          >
            Next
          </button>
        </div>
      </div>

      {/* CRUD Modal for Adding/Editing */}
      {isModalOpen && (
        <PromoCodeModal
          promoCode={editData}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default PromoCode;

