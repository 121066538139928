import React from 'react';
import AppRoutes from './routes/Routes'; // Your routes component
import { UserContextProvider } from './context/UserContext'; // Make sure this path is correct
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js'; // Stripe Elements
import { loadStripe } from '@stripe/stripe-js'; // Load Stripe

// Load your Stripe public key (replace with your actual key)
const stripePromise = loadStripe("pk_test_51QNujgRWayS4Wy3FHzXqYmLZe3Jfx8Z339S19E0R9QDVG7QIoW5lIvPqQBUVrstar1X7uNmaLDiXNnSfBGlBEGUy00YXciSG4K"); // Replace this with your real key

const App = () => {
  return (
    <UserContextProvider>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}> {/* Wrap your app in Elements */}
          <div>
            <AppRoutes /> {/* Your routing component */}
            <ToastContainer />
          </div>
        </Elements>
      </ThemeProvider>
    </UserContextProvider>
  );
};

export default App;
