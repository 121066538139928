import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarCheck,
    faUsers,
    faGem,
    faMoneyCheckAlt,
    faGift,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import CustomerMap from "../../components/dashboard/CustomerMap";
import TotalRevenue from "../../components/dashboard/TotalRevenue";
import { Link } from "react-router-dom";
import { routes } from "../../config/utility";

const AdminDashboard = () => {
    // Sample data for charts
    const weeklyTrendsData = [
        { day: "Mon", Bookings: 20, Revenue: 200 },
        { day: "Tue", Bookings: 35, Revenue: 350 },
        { day: "Wed", Bookings: 50, Revenue: 500 },
        { day: "Thu", Bookings: 40, Revenue: 400 },
        { day: "Fri", Bookings: 60, Revenue: 600 },
        { day: "Sat", Bookings: 80, Revenue: 800 },
        { day: "Sun", Bookings: 45, Revenue: 450 },
    ];

    const membershipDistributionData = [
        { name: "Silver", value: 60 },
        { name: "Gold", value: 25 },
        { name: "Platinum", value: 15 },
    ];

    return (
        <div className=" px-6 space-y-12 ">
            {/* Welcome Banner */}
            <div className=" p-8 rounded-3xl hover:shadow-primary transition-transform transform ">
                <h1 className="text-5xl font-bold text-primary text-center">
                    Welcome, Admin 🌟
                </h1>
                <p className="text-body text-center mt-4 text-lg">
                    Manage your bookings, track revenue, and oversee your customers—all in
                    one elegant dashboard.
                </p>
            </div>

            {/* Stats Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {[
                    {
                        label: "Total Bookings",
                        count: 120,
                        icon: faCalendarCheck,
                        color: "text-primary",
                    },
                    {
                        label: "Total Revenue",
                        count: "AED 50,000",
                        icon: faMoneyCheckAlt,
                        color: "text-primary",
                    },
                    {
                        label: "Total Customers",
                        count: 340,
                        icon: faUsers,
                        color: "text-primary",
                    },
                    {
                        label: "Memberships Sold",
                        count: "Gold: 25, Silver: 60",
                        icon: faGem,
                        color: "text-primary",
                    },
                ].map((item, idx) => (
                    <motion.div
                        key={idx}
                        className="bg-white p-8 rounded-3xl shadow-lg flex items-center space-x-6 hover:shadow-primary transition-transform transform "
                    >
                        <div className="flex items-center justify-center w-14 h-14 rounded-full bg-light">
                            <FontAwesomeIcon
                                icon={item.icon}
                                className={`text-3xl ${item.color}`}
                            />
                        </div>
                        <div>
                            <h4 className="text-2xl font-bold text-primary">{item.count}</h4>
                            <p className="text-body text-lg">{item.label}</p>
                        </div>
                    </motion.div>
                ))}
            </div>

            {/* Customer Map and Revenue */}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
                <div className="md:col-span-6 lg:col-span-6">
                    <TotalRevenue />

                </div>
                <div className="md:col-span-6 lg:col-span-6">
                    <CustomerMap />

                </div>
            </div>

            {/* Booking Trends Section */}
            <div className="bg-white p-10 rounded-3xl shadow-lg hover:shadow-primary transition-transform transform ">
                <h2 className="text-3xl font-bold text-primary mb-6">
                    Weekly Booking Trends & Revenue 📊
                </h2>
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={weeklyTrendsData}>
                        <CartesianGrid stroke="#f0f0f0" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <defs>
                            <linearGradient id="colorBookings" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8C7A6B" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#D4B6A7" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <Area
                            type="monotone"
                            dataKey="Bookings"
                            stroke="#8C7A6B"
                            fill="url(#colorBookings)"
                            strokeWidth={2}
                        />
                        <Area
                            type="monotone"
                            dataKey="Revenue"
                            stroke="#C5A880"
                            fillOpacity={0.3}
                            fill="#C5A880"
                            strokeWidth={2}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>


        </div>
    );
};

export default AdminDashboard;
