import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Sample data for the chart
const data = [
  { name: 'Sun', 2020: 60, 2021: 80 },
  { name: 'Mon', 2020: 30, 2021: 70 },
  { name: 'Tue', 2020: 40, 2021: 60 },
  { name: 'Wed', 2020: 50, 2021: 50 },
  { name: 'Thu', 2020: 60, 2021: 70 },
  { name: 'Fri', 2020: 50, 2021: 80 },
  { name: 'Sat', 2020: 70, 2021: 60 },
];

// Custom Tooltip Styling
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 shadow-lg rounded-md text-xs text-dark">
        <p className="font-semibold">{payload[0].payload.name}</p>
        <p className="text-primary">2020: {payload[0].value}</p>
        <p className="text-accent">2021: {payload[1].value}</p>
      </div>
    );
  }
  return null;
};

const CustomerMap = () => {
  return (
    <div className="bg-white rounded-xl p-6 shadow-lg">
      {/* Header with Title and Dropdown */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-dark text-xl font-bold">Customer Map</h2>
        <div className="flex items-center space-x-2">
          {/* Dropdown */}
          <div className="relative">
            <select className="border border-grey p-2 rounded-md text-body appearance-none pr-6">
              <option>Weekly</option>
              <option>Monthly</option>
            </select>
            <FontAwesomeIcon
              icon={faCaretDown}
              className="absolute top-3 right-2 text-body pointer-events-none"
            />
          </div>
          {/* Icon for additional options */}
          <FontAwesomeIcon icon={faEllipsisV} className="text-body" />
        </div>
      </div>

      {/* Chart Container */}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          {/* Grid */}
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E5E5" />
          {/* X and Y Axes */}
          <XAxis
            dataKey="name"
            tick={{ fill: '#8C7A6B' }}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
          />
          <YAxis
            tick={{ fill: '#8C7A6B' }}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            domain={[0, 100]}
          />
          {/* Tooltip */}
          <Tooltip content={<CustomTooltip />} />
          {/* Bars with rounded corners */}
          <Bar dataKey="2020" fill="#8C7A6B" radius={[10, 10, 0, 0]} />
          <Bar dataKey="2021" fill="#D4B6A7" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomerMap;
