import { StatsCard } from "../components/StatsCard";
import { UserCard } from "../components/UserCard";
import React, { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Menu, Dialog, Transition, DialogBackdrop } from "@headlessui/react";
import api from "../api/api"; // Assuming your API utility is in this path
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Users = () => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editData, setEditData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [meta, setMeta] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 1
    });
    const [statData, setStatData] = useState({
        active: 0,
        viewed: 0,
    });

    // Fetch tools on mount and when filters change
    useEffect(() => {
        fetchData();
    }, [filter, currentPage]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await api.get("/users", {
                params: {
                    type: "user",
                    search: searchTerm,
                    filter,
                    page: currentPage,
                },
            });
            setUsers(response.data);
            setMeta(response.meta);
            setStatData((prev) => ({
                ...prev,
                active: response.active,
                viewed: response.viewed,
            }));
            setLoading(false);
        } catch (error) {
            toast.error("Failed to fetch");
            setLoading(false);
        }
    };

    const handleEdit = (user) => {
        setEditData(user);
        setIsModalOpen(true); // Show modal for editing
    };

    const handleAdd = () => {
        setEditData(null); // Reset any edit data
        setIsModalOpen(true); // Show modal for adding
    };

    const handleSave = () => {
        setIsModalOpen(false); // Hide modal after saving
        fetchData();
    };

    const handleCancel = () => {
        setIsModalOpen(false); // Hide modal without saving
    };

    // Pagination handlers
    const handleNextPage = () => {
        if (meta.current_page < meta.last_page) {
            setCurrentPage(meta.current_page + 1);
        }
    };

    const handlePrevPage = () => {
        if (meta.current_page > 1) {
            setCurrentPage(meta.current_page - 1);
        }
    };

    // Render individual page buttons
    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= meta.last_page; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    className={`px-3 py-1 rounded-lg border border-primary ${i === meta.current_page ? 'bg-primary text-white' : 'bg-white text-gray-700'
                        } hover:bg-primary hover:text-white mx-1`}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };


    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            try {
                await api.delete(`/users/${id}`);
                toast.success("User deleted successfully.");
                fetchData(); // Refresh data
            } catch (error) {
                toast.error("Failed to delete user.");
            }
        }
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-6 ">
            <div className="grid grid-cols-12 gap-4 w-full mb-6">
                {/* Search Bar (Column 1) */}
                <div className="col-span-10 relative ">
                    <input
                        type="text"
                        className="w-full py-2 pl-4 pr-4 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:outline-none  focus:ring-primary"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Keep updating state as user types
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                fetchData(); // Only set searchTerm on Enter key press
                            }
                        }}
                    />
                    <svg
                        className="absolute right-3 top-3 w-5 h-5 "
                        width="24"
                        height="25"
                        viewBox="0 0 20 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M21.7444 24.25L16.8389 19.6C16.0333 20.2444 15.1069 20.7546 14.0597 21.1306C13.0125 21.5065 11.8981 21.6944 10.7167 21.6944C7.78982 21.6944 5.31273 20.6808 3.28542 18.6535C1.2581 16.6262 0.244446 14.1491 0.244446 11.2222C0.244446 8.29537 1.2581 5.81829 3.28542 3.79097C5.31273 1.76366 7.78982 0.75 10.7167 0.75C13.6435 0.75 16.1206 1.76366 18.1479 3.79097C20.1752 5.81829 21.1889 8.29537 21.1889 11.2222C21.1889 12.4037 21.0009 13.5181 20.625 14.5653C20.2491 15.6125 19.7389 16.5389 19.0944 17.3444L24 21.9944L21.7444 24.25ZM10.7167 18.4722C12.7306 18.4722 14.4424 17.7674 15.8521 16.3576C17.2618 14.9479 17.9667 13.2361 17.9667 11.2222C17.9667 9.20833 17.2618 7.49653 15.8521 6.08681C14.4424 4.67708 12.7306 3.97222 10.7167 3.97222C8.70278 3.97222 6.99097 4.67708 5.58125 6.08681C4.17153 7.49653 3.46667 9.20833 3.46667 11.2222C3.46667 13.2361 4.17153 14.9479 5.58125 16.3576C6.99097 17.7674 8.70278 18.4722 10.7167 18.4722Z"
                            fill="#CACACA"
                        />
                    </svg>
                </div>

                {/* Add New User Button (Column 2) */}
                <div className="col-span-2 flex justify-end">
                <button onClick={handleAdd} className="px-4 py-2 text-white bg-primary rounded-lg flex items-center hover:bg-accent focus:outline-none focus:ring-2 focus:ring-accent">
                        + Add New User
                    </button>
                </div>
            </div>

            {/* Search Bar */}

            {/* Stats Cards */}
            <div className="grid grid-cols-3 gap-6 mb-12">
                <StatsCard title="Total Users" value={meta.total} />
                <StatsCard title="Active Runtime" value={statData.active} />
                <StatsCard title="Viewed" value={statData.viewed} />
            </div>

            {/* User List */}
            <div className="space-y-4">
                {users.map((user, index) => (
                    <UserCard
                        key={user.id}
                        user={user}
                        handleEdit={() => handleEdit(user)} // Pass the user to the handleEdit function
                        handleDelete={() => handleDelete(user.id)} // Pass the user to the handleDelete function
                    />
                ))}
            </div>

            {/* Pagination */}
            <div className="mt-4 flex justify-between items-center">
                {/* Total Count and Pagination Info */}
                <span className="text-gray-500">
                    Showing {meta.from} to {meta.to} of {meta.total} customers
                </span>

                {/* Page Number Buttons */}
                <div className="flex items-center space-x-2">
                    <button
                        onClick={handlePrevPage}
                        disabled={meta.current_page === 1}
                        className={`px-4 py-2 text-sm text-gray-500 bg-white border border-gray-200 rounded-lg ${meta.current_page === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'
                            }`}
                    >
                        Previous
                    </button>

                    {/* Render dynamic page numbers */}
                    {renderPageNumbers()}

                    <button
                        onClick={handleNextPage}
                        disabled={meta.current_page === meta.last_page}
                        className={`px-4 py-2 text-sm text-gray-500 bg-white border border-gray-200 rounded-lg ${meta.current_page === meta.last_page ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'
                            }`}
                    >
                        Next
                    </button>
                </div>
            </div>

            {/* CRUD Modal for Adding/Editing */}
            {isModalOpen && (
                <CRUModal
                    item={editData}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            )}

        </div>
    );
};

export default Users;


export const CRUModal = ({ item, onSave, onCancel }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        dob: '',
        phone: '+971', // Default to Dubai country code
    });

    useEffect(() => {
        if (item) {
            setFormData({
                name: item.name || '',
                email: item.email || '',
                dob: item.dob || '',
                phone: item.phone || '+971',
                password: '', // Leave blank for security
            });
        }
    }, [item]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phone: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (item) {
                await api.post(`/users/${item.id}`, {
                    ...formData,
                    _method: 'PUT',
                    type: "user",
                });
                toast.success("User updated successfully");
            } else {
                setFormData({ ...formData, type: 'user' });
                await api.post(`/users`, {
                    ...formData,
                    type: "user",
                });
                toast.success("User created successfully");
            }
            onSave(); // Refresh list and close modal
        } catch (error) {
        }
    };

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onCancel}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {item ? 'Edit User' : 'Add New User'}
                                </Dialog.Title>
                                <div className="mt-4">
                                    <form onSubmit={handleSubmit}>
                                        {/* Name */}
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                                className="block w-full p-2 border rounded-lg"
                                            />
                                        </div>

                                        {/* Email */}
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                className="block w-full p-2 border rounded-lg"
                                            />
                                        </div>

                                        {/* Password */}
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required={!item} // Only required for new users
                                                className="block w-full p-2 border rounded-lg"
                                                placeholder={item ? 'Leave blank to keep current password' : ''}
                                            />
                                        </div>


                                        {/* Phone */}
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                                            <PhoneInput
                                                country={'ae'} // Default country (Dubai)
                                                value={formData.phone}
                                                onChange={handlePhoneChange}
                                                inputClass="!w-full p-5 !pl-12 " // Adjust padding for better alignment
                                                searchPlaceholder="Search countries" // Placeholder for the search bar
                                                enableSearch={true} // Enable the search feature in the dropdown
                                                containerClass="!w-full !border !rounded-lg flex" // Ensure the container fits nicely
                                                dropdownClass="!bg-white !shadow-lg !rounded-lg !p-2" // Style the dropdown
                                                autoFormat={true} // Automatically format the number
                                            />

                                        </div>

                                        {/* DOB */}
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Date of Birth</label>
                                            <input
                                                type="date"
                                                name="dob"
                                                value={formData.dob}
                                                onChange={handleChange}
                                                className="block w-full p-2 border rounded-lg"
                                            />
                                        </div>

                                        {/* Buttons */}
                                        <div className="flex justify-end mt-6">
                                            <button
                                                type="button"
                                                onClick={onCancel}
                                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary"
                                            >
                                                {item ? 'Update' : 'Save'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};


