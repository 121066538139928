import React from 'react';
import { motion } from 'framer-motion';
import 'react-phone-input-2/lib/style.css';

export const StepWizard = ({ step, setStep, steps }) => {
    return (
        <div className="relative w-full p-10">
            {/* Step Wizard Container */}
            <div className="flex justify-between w-full relative z-10">
                {steps.map((stepItem, index) => {
                    const isActive = stepItem.number === step;
                    const isCompleted = stepItem.number < step;
                    const isUpcoming = stepItem.number === step + 1;

                    return (
                        <div key={stepItem.number} className="relative flex-1 text-center">
                            {/* Step Indicator */}
                            <motion.div
                                className={`w-10 h-10 mx-auto rounded-full flex items-center justify-center font-semibold transition-colors duration-300 ${isActive
                                    ? 'bg-gradient-to-r from-gradientStart to-gradientEnd text-white shadow-lg' // Active step gradient
                                    : isCompleted
                                        ? 'bg-accent text-white' // Completed step accent color
                                        : 'bg-bodyMain border text-body' // Upcoming/Inactive step
                                    }`}
                                animate={isActive ? {
                                    scale: [1, 1.1, 1],     // Slight bounce effect
                                    rotate: [0, 10, -10, 0], // Twinkling star effect
                                    boxShadow: [
                                        '0px 0px 10px rgba(196, 168, 128, 0.4)', // Glow for active
                                        '0px 0px 20px rgba(196, 168, 128, 0.6)',
                                        '0px 0px 30px rgba(196, 168, 128, 0.4)',
                                    ]
                                } : isUpcoming ? {
                                    // Subtle shine effect for upcoming step
                                    boxShadow: [
                                        '0px 0px 5px rgba(196, 168, 128, 0.4)', // Glow for upcoming
                                        '0px 0px 10px rgba(196, 168, 128, 0.6)',
                                        '0px 0px 5px rgba(196, 168, 128, 0.4)'
                                    ]
                                } : {}}
                                transition={{
                                    duration: 1.5,
                                    repeat: isActive || isUpcoming ? Infinity : 0,
                                    repeatType: "reverse",
                                    ease: "easeInOut",
                                }}
                                whileHover={isCompleted ? { scale: 1.1 } : {}}
                                onClick={() => isCompleted && setStep(stepItem.number)}
                            >
                                {stepItem.number}
                            </motion.div>

                            {/* Step Label */}
                            <p
                                className={`mt-2 text-sm font-semibold tracking-wide transition-colors duration-300 ${isActive ? 'text-primary' : isCompleted ? 'text-secondary' : 'text-grey'
                                    }`}
                            >
                                {stepItem.label}
                            </p>

                            {/* Connector Line */}
                            {index < steps.length - 1 && (
                                <motion.div
                                    className="absolute top-1/3 left-1/2 w-full h-1 transform -translate-y-1/2"
                                    initial={{ width: '0%' }}
                                    animate={{
                                        width: isCompleted ? '100%' : isActive ? '0%' : '0%',
                                        backgroundColor: isCompleted ? 'rgb(197, 168, 128)' : 'rgb(237, 234, 230)',
                                    }}
                                    transition={{ duration: 0.5 }}
                                    style={{ zIndex: -1 }}
                                ></motion.div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
