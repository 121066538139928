import React, { useContext } from 'react';
import StatsCard from './../components/dashboard/StatsCard';
import CustomerMap from '../components/dashboard/CustomerMap';
import TotalRevenue from '../components/dashboard/TotalRevenue';
import { UserContext } from '../context/UserContext';
import UserDashboard from './User/Dashboard';
import UnpaidUserDashboard from './User/UnpaidDashboard';
import AdminDashboard from './Admin/Dashboard';
import AdminProfile from './Admin/Profile';
import UserProfile from './User/Profile';
import { ROLES } from '../config/constants';
import StaffProfile from './Staff/Profile';

const Profile = () => {
  const { roles } = useContext(UserContext); // Get the roles from context

  return (
    <div className="p-6 space-y-6">
      {roles.includes(ROLES.ADMIN) && (

        <AdminProfile />
      )}

      {roles.includes(ROLES.USER) && (

        <UserProfile />
      )}

      {roles.includes(ROLES.STAFF) && (

        <StaffProfile />
      )}

    </div>
  );
};

export default Profile;
