import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Authentication/Login';
import { ROLES } from '../config/constants';
import PrivateRoute from '../components/routes/PrivateRoute';
import Dashboard from '../pages/Dashboard';
import { routes } from './../config/utility';
import { UserContext } from '../context/UserContext';
import PromoCode from '../pages/Setting/PromoCode';
import Users from '../pages/Users';
import SingleUser from '../pages/SingleUser';
import Membership from '../pages/Setting/Membership';
import Role from '../pages/Setting/Role';
import AccessDeniedPage from '../pages/Error/403';
import NotFoundPage from '../pages/Error/404';
import Profile from '../pages/Profile';
import Service from '../pages/Setting/Service';
import Register from '../pages/Authentication/Register';
import VerifyOtp from '../pages/Authentication/VerifyOtp';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import ResetPassword from '../pages/Authentication/ResetPassword';
import Booking from '../pages/Booking';
import Staff from '../pages/Staff';
import Color from '../pages/Setting/Color';

const AppRoutes = () => {
  const { user, roles } = useContext(UserContext);

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path={routes.login} element={!user?.isAuthenticated ? <Login /> : <Navigate to={routes.dashboard} />} />
        <Route path={routes.registration} element={!user?.isAuthenticated ? <Register /> : <Navigate to={routes.dashboard} />} />
        <Route path={routes.forgot_password} element={!user?.isAuthenticated ? <ForgotPassword /> : <Navigate to={routes.dashboard} />} />
        <Route path={routes.reset_password} element={!user?.isAuthenticated ? <ResetPassword /> : <Navigate to={routes.dashboard} />} />
        <Route path={routes.verify} element={!user?.email_verified_at ? <VerifyOtp /> : <Navigate to={routes.dashboard} />} />

        {/* Admin and User can access these routes */}
        <Route path={routes.dashboard} element={<PrivateRoute allowedRoles={[ROLES.ADMIN, ROLES.USER, ROLES.STAFF]}><Dashboard /></PrivateRoute>} />
        <Route path={routes.profile} element={<PrivateRoute allowedRoles={[ROLES.ADMIN, ROLES.USER, ROLES.STAFF]}><Profile /></PrivateRoute>} />
        <Route path={routes.booking} element={<PrivateRoute allowedRoles={[ROLES.ADMIN, ROLES.USER, ROLES.STAFF]}><Booking /></PrivateRoute>} />
        <Route
          path={routes.booking}
          element={
            roles.includes(ROLES.USER) ? (
              !user?.paid ? (
                <VerifyOtp />
              ) : (
                <PrivateRoute allowedRoles={[ROLES.USER]}>
                  <Dashboard />
                </PrivateRoute>
              )
            ) : (
              <PrivateRoute allowedRoles={[ROLES.ADMIN]}>
                <Dashboard />
              </PrivateRoute>
            )
          }
        />



        {/* Only Admins can access these routes*/}
        <Route path={routes.staff} element={<PrivateRoute allowedRoles={[ROLES.ADMIN]}><Staff /></PrivateRoute>} />
        <Route path={routes.user} element={<PrivateRoute allowedRoles={[ROLES.ADMIN]}><Users /></PrivateRoute>} />
        <Route path={routes.user_detail} element={<PrivateRoute allowedRoles={[ROLES.ADMIN]}><SingleUser /></PrivateRoute>} />
        <Route path={routes.promo_code} element={<PrivateRoute allowedRoles={[ROLES.ADMIN]}><PromoCode /></PrivateRoute>} />
        <Route path={routes.membership} element={<PrivateRoute allowedRoles={[ROLES.ADMIN]}><Membership /></PrivateRoute>} />
        <Route path={routes.service} element={<PrivateRoute allowedRoles={[ROLES.ADMIN]}><Service /></PrivateRoute>} />
        <Route path={routes.color} element={<PrivateRoute allowedRoles={[ROLES.ADMIN]}><Color /></PrivateRoute>} />
        <Route path={routes.role} element={<PrivateRoute allowedRoles={[]}><Role /></PrivateRoute>} />

        {/* Access Denied Page */}
        <Route path={routes['403']} element={<AccessDeniedPage />} />

        {/* Redirect other paths */}
        <Route path={routes.home} element={<Navigate to={routes.dashboard} />} />

        {/* Catch-all for undefined routes */}
        <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </Router>
  );
};

export default AppRoutes;


{/* <Route path={routes.verify} element={!user?.isAuthenticated ? <VerifyOtp /> : <Navigate to={routes.dashboard} />} /> */ }
