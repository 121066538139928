import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const AppointmentDaySelector = ({ formData, setFormData }) => {
    const [availableDays, setAvailableDays] = useState([]);

    useEffect(() => {
        const now = new Date();
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        // Generate the next 7 days (excluding Sunday) starting from today
        const days = [];
        for (let i = 0; days.length < 6; i++) {
            const currentDay = new Date(now.getTime() + i * 24 * 60 * 60 * 1000);
            if (currentDay.getDay() === 0) continue; // Skip Sunday
            const isToday = i === 0;
            days.push({
                name: isToday ? 'Today' : daysOfWeek[currentDay.getDay() - 1], // Map weekdays, skipping Sunday
                date: currentDay.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' }),
                isToday,
                rawDate: currentDay.toISOString().split('T')[0] // Used for <input type="date" />
            });
        }

        setAvailableDays(days);
    }, []);

    // Handle custom date selection
    const handleCustomDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const formattedDate = selectedDate.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
        
        setFormData({ ...formData, day: formattedDate, slot: '' });
    };

    return (
        <div className="px-6 pb-6 rounded-lg">
            {/* Header Section */}
            <h2 className="text-primary text-4xl font-bold mb-4 text-center">
                Select a Day for Your Appointment
            </h2>
            <p className="text-gradientEnd text-xl mb-6 text-center">
                Pick the day that works best for you, and we’ll confirm the timing after checking our staff’s availability.
            </p>

            {/* Grid of Days */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {availableDays.map((day) => (
                    <motion.div
                        key={day.date}
                        className={`p-5 rounded-lg cursor-pointer transition-all duration-300 shadow-lg ${formData.day === day.date
                            ? 'bg-gradientEnd text-white' // Selected item styling
                            : 'bg-bodyMain text-dark border border-gray-200' // Default item styling
                            }`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => {
                            setFormData({ ...formData, day: day.date, slot: '' });
                        }}
                    >
                        <div className="text-center">
                            <p
                                className={`text-lg ${day.isToday && formData.day !== day.date ? 'text-primary underline' : ''}`}
                            >
                                {day.name}
                            </p>
                            <p
                                className={`text-sm ${formData.day === day.date ? 'text-white' : 'text-gray-500'}`}
                            >
                                {day.date}
                            </p>
                        </div>
                    </motion.div>
                ))}

                {/* Date Picker Slot */}
                <div className="col-span-full p-2">
    <div className="w-full max-w-sm py-3 px-8 mx-auto rounded-lg cursor-pointer transition-all duration-300 shadow-lg bg-bodyMain border border-gray-200 hover:border-primary hover:shadow-lg">
        <div className="flex flex-col items-center justify-center h-full">
            <p className="text-lg text-center font-medium text-dark mb-2">Pick Another Date</p>
            <input
                type="date"
                className="w-full border border-gray-300 rounded-md text-center text-gray-700 p-2 cursor-pointer focus:ring-2 focus:ring-primary"
                min={availableDays[availableDays.length - 1]?.rawDate} // Start from the last available day
                onChange={handleCustomDateChange}
            />
        </div>
    </div>
</div>

            </div>

            {/* Confirmation Section */}
            {formData.day && (
                <div className="mt-6 text-center">
                    <p className="text-secondary text-lg font-medium">
                        You’ve selected: <span className="text-primary font-bold">{formData.day}</span>
                    </p>
                    <p className="text-sm text-gray-500">
                        Our team will reach out to confirm the timing based on staff availability.
                    </p>
                </div>
            )}
        </div>
    );
};

export default AppointmentDaySelector;